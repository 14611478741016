import { getCatalogMock, mockedCatalog } from './api/catalog/catalog.mocks';
import {
  MOCKED_USER_CPF,
  MOCKED_USER_ID,
} from './api/constants/purchaseDataConstants';
import {
  createOrder,
  getProductDescription,
  getProductMock,
} from './api/product/product.mocks';
import {
  createAddressMock,
  createUserMock,
  identificationDocuments,
  signOrderContract,
  validateUserCPFMock,
} from './api/users/user.mocks';

export type MockConfig = {
  delay?: number;
};

export type Mock = {
  handler: () => Promise<Response>;
  config?: MockConfig;
};

type MockMappings = {
  [path: string]: {
    [method: string]: Mock;
  };
};

const productDetailUrl =
  'https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/index.html';

export const mockMappings: MockMappings = {
  '/catalog': {
    GET: { handler: getCatalogMock },
  },
  [productDetailUrl]: {
    GET: { handler: getProductDescription },
  },
  '/users': {
    POST: { handler: createUserMock },
  },
  [`/users/${MOCKED_USER_CPF}`]: {
    GET: { handler: validateUserCPFMock },
  },
  [`/users/${MOCKED_USER_ID}/addresses`]: {
    POST: { handler: createAddressMock },
  },
  '/orders': {
    POST: { handler: createOrder },
  },
  [`/users/${MOCKED_USER_ID}/orders//sign`]: {
    PATCH: { handler: signOrderContract },
  },
  [`/users/${MOCKED_USER_ID}/identificationDocuments`]: {
    POST: { handler: identificationDocuments },
  },
};

const getProductsUrl = (): void => {
  mockedCatalog.map(
    item =>
      (mockMappings[`/products/${item.id}`] = {
        GET: { handler: getProductMock },
      }),
  );
};

getProductsUrl();
