export enum PaymentMethodType {
  FULL = 'FULL',
}

export interface PaymentMethod {
  id: string;
  installments: number;
  monthlyPrice: string;
  interestPercentage: string;
  totalPrice: string;
  type: PaymentMethodType;
  balloonPercentage?: number;
  balloonPrice?: string;
}
