import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core';

import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';

import { CrdShoppingCartItem } from '../components/shopping-cart-item.component';
import { useSummaryCommands } from '../summary.commands';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr 5fr',
      gap: `32px ${theme.layout.columnGap}`,
      marginTop: '32px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 2fr',
        marginTop: '16px',
      },
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
);
interface Props {
  shoppingCartItems: ShoppingCartItem[];
}

export const CrdShoppingCartContainer: React.FC<Props> = ({
  shoppingCartItems,
}: Props) => {
  const classes = useStyles();
  const { removeProductFromShoppingCart } = useSummaryCommands();

  const handleRemoveShoppingCartItem = (): void => {
    removeProductFromShoppingCart().subscribe();
  };

  return (
    <>
      {shoppingCartItems.map((shoppingCartItem, index) => {
        return (
          <CrdShoppingCartItem
            key={index}
            item={shoppingCartItem}
            onRemoveItem={handleRemoveShoppingCartItem}
            className={classes.container}
          ></CrdShoppingCartItem>
        );
      })}
    </>
  );
};
