import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import { AppRoutes } from 'pages/app.routes';
import { CatalogPage } from 'pages/catalog/catalog.page';
import { FaqPage } from 'pages/faq/faq.page';
import { HowItWorksPage } from 'pages/how-it-works/how-it-works.page';
import { IphoneComparisonPage } from 'pages/iphone-comparison/iphone-comparison.page';
import { EmptyPage } from 'pages/misc/empty.page';
import { ProductPage } from 'pages/product/product.page';
import { SummaryPage } from 'pages/summary/summary.page';
import { TermsAndConditionsPage } from 'pages/terms-and-conditions/terms-and-conditions.page';

const App: React.FC = () => {
  return (
    <Suspense fallback={<EmptyPage />}>
      <Router basename={process.env.REACT_APP_STORE_PATH}>
        <Route path={AppRoutes.CatalogPage} exact component={CatalogPage} />
        <Route path={AppRoutes.FaqPage} component={FaqPage} />
        <Route
          path={AppRoutes.IphoneComparisonPage}
          component={IphoneComparisonPage}
        />
        <Route path="/apple/:id" component={ProductPage} />
        <Route path={AppRoutes.SummaryPage} component={SummaryPage} />
        <Route path={AppRoutes.HowItWorksPage} component={HowItWorksPage} />
        <Route
          path={AppRoutes.TermsAndConditionsPage}
          component={TermsAndConditionsPage}
        />
      </Router>
    </Suspense>
  );
};

export default App;
