import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Hidden, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageWrapper: {
      display: 'flex',
      flexDirection: 'row-reverse',
      justifyContent: 'center',
    },
    imageContainer: {
      position: 'relative',
      width: '66%',
      paddingTop: '66%',
      [theme.breakpoints.down('md')]: {
        width: '100%',
        paddingTop: '100%',
      },
    },
    image: {
      width: '100%',
      position: 'absolute',
      top: 0,
      height: '100%',
      objectFit: 'contain',
    },
    headlineContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginTop: '16px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        marginTop: 0,
      },
    },
    name: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '31px',
      color: theme.palette.text.primary,
      margin: 0,
    },
    installment: {
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '31px',
      color: theme.palette.text.primary,
      margin: 0,
      textAlign: 'right',
      [theme.breakpoints.down('md')]: {
        textAlign: 'left',
      },
      [theme.breakpoints.only('xs')]: {
        fontWeight: 'normal',
      },
    },
    divider: {
      marginTop: 0,
      marginBottom: '16px',
      [theme.breakpoints.down('md')]: {
        marginTop: '24px',
      },
      [theme.breakpoints.only('xs')]: {
        marginTop: '16px',
      },
    },
    descriptionContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      gap: `16px 79px`,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    descriptionItemTitle: {
      fontWeight: 'bold',
      fontSize: '14px',
      lineHeight: '21px',
      margin: 0,
    },
    descriptionItemText: {
      color: theme.palette.text.secondary,
      fontSize: '14px',
      lineHeight: '21px',
      margin: 0,
    },
    descriptionItemAnchor: {
      fontSize: '10px',
      lineHeight: '21px',
      margin: 0,
      textDecoration: 'underline',
      color: theme.palette.text.secondary,
    },
    details: {},
    balloon: {
      [theme.breakpoints.down('md')]: {
        order: 1,
      },
      [theme.breakpoints.only('xs')]: {
        order: 0,
      },
    },
    quantity: {
      textAlign: 'right',
      [theme.breakpoints.only('xs')]: {
        textAlign: 'left',
      },
    },
    removeProduct: {
      [theme.breakpoints.up('lg')]: {
        marginTop: '40px',
        textAlign: 'right',
      },
    },
    removeProductLink: {
      color: theme.colors.neutral.N_07,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  }),
);

interface Props {
  item: ShoppingCartItem;
  onRemoveItem: () => void;
  className: string;
}

export const CrdShoppingCartItem: React.FC<Props> = ({
  item,
  onRemoveItem,
  className,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const removeProductLink = (
    <div className={classes.removeProduct}>
      <div
        className={classes.removeProductLink}
        onClick={onRemoveItem}
        onKeyPress={onRemoveItem}
        role="link"
        tabIndex={0}
      >
        {t('SHOPPING_CART.BODY.REMOVE_TITLE')}
      </div>
    </div>
  );
  return (
    <div className={className} key={item.variation.id}>
      <div className={classes.imageWrapper}>
        <div className={classes.imageContainer}>
          <img
            className={classes.image}
            alt={item.variation.media[0].source}
            src={item.variation.media[0].source}
          />
        </div>
      </div>
      <div>
        <div className={classes.headlineContainer}>
          <h4 className={classes.name}>{item.product.displayName}</h4>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.descriptionContainer}>
          <div className={classes.details}>
            <h4 className={classes.descriptionItemTitle}>
              {t('SHOPPING_CART.BODY.COLOR_TITLE')}
            </h4>
            <p className={classes.descriptionItemText}>
              {t(item.variation.color.nameLiteral)}
            </p>
          </div>
          <div className={classes.balloon}>
            <h4 className={classes.descriptionItemTitle}>
              {t('SHOPPING_CART.BODY.STORAGE_TITLE')}
            </h4>
            <p className={classes.descriptionItemText}>
              {item.variation.capacity}
            </p>
          </div>
          <div className={classes.quantity}>
            <h4 className={classes.descriptionItemTitle}>
              {t('SHOPPING_CART.BODY.QUANTITY_TITLE')}
            </h4>
            <p className={classes.descriptionItemText}>1</p>
            <Hidden lgUp>{removeProductLink}</Hidden>
          </div>
        </div>

        <Hidden mdDown>{removeProductLink}</Hidden>
      </div>
    </div>
  );
};
