import { Observable, of } from 'rxjs';

import * as productApi from 'api/product.api';
import { Product } from 'domain/product/product.types';
import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';
import { addShoppingCartItem } from 'redux/shoppingCart/shoppingCart.actions';
import { store } from 'redux/store';

interface ProductCommands {
  selectProduct: (shoppingCartItem: ShoppingCartItem) => Observable<undefined>;
  getProduct: (productId: string) => Observable<Product>;
}

const selectProduct = (
  shoppingCartItem: ShoppingCartItem,
): Observable<undefined> => {
  store.dispatch(addShoppingCartItem(shoppingCartItem));
  return of(undefined);
};

const getProduct = (productId: string): Observable<Product> =>
  productApi.getProduct(productId);

export const useProductCommands = (): ProductCommands => {
  return {
    selectProduct,
    getProduct,
  };
};
