import React from 'react';
import { useTranslation } from 'react-i18next';

import { Hidden, Theme, Typography } from '@material-ui/core';
import Add from '@material-ui/icons/Add';
import Remove from '@material-ui/icons/Remove';
import { createStyles, makeStyles } from '@material-ui/styles';

import { FaqItem } from 'domain/faq/faq.types';
import { CrdTranslator } from 'modules/shared/components/translator/translator.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      '&:nth-child(1)': {
        borderTop: `1px solid ${theme.colors.neutral.N_11}`,
      },
      borderBottom: `1px solid ${theme.colors.neutral.N_11}`,
      display: 'grid',
      gridTemplateColumns: '1fr 9fr',
      columnGap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 5fr',
      },
    },
    summary: {
      cursor: 'pointer',
      gridColumn: '1 / span 2',
      display: 'grid',
      columnGap: theme.layout.columnGap,
      gridTemplateColumns: '1fr 9fr',
      outline: 'none',
      minHeight: '80px',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 5fr',
      },
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
        minHeight: '70px',
      },
    },
    detail: {
      gridColumnStart: '2',
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      [theme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
    summaryContainer: {
      padding: '24px 0',
    },
    summaryText: {
      gridColumnStart: '2',
      lineHeight: '24px',
      fontWeight: 'normal',
      fontSize: '16px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
    summaryIcon: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: theme.colors.neutral.N_07,
    },
  }),
);

interface Props {
  id: number;
  item: FaqItem;
  onClick: (itemId: number) => void;
  expanded: boolean;
}
export const CrdExpansionPanelItem: React.FC<Props> = ({
  id,
  item,
  onClick,
  expanded,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const clickHandler = (): void => onClick(id);

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.summary}
        onClick={clickHandler}
        onKeyPress={clickHandler}
        role="link"
        tabIndex={0}
      >
        <Hidden xsDown>
          <div className={classes.summaryIcon}>
            {expanded ? <Remove /> : <Add />}
          </div>
        </Hidden>
        <div className={classes.summaryContainer}>
          <Typography
            className={classes.summaryText}
            variant="h6"
            component="h2"
          >
            {t(item.questionLiteral)}
          </Typography>
          {expanded && (
            <Typography component="h3" className={classes.detail}>
              <CrdTranslator literal={item.answerLiteral} />
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};
