import React, { useEffect } from 'react';

import { Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { CrdFooter } from 'modules/shared/components/footer/footer.component';
import { HeaderContainer } from 'modules/shared/containers/header/header.container';
import { ScrollProvider } from 'services/scroll/scroll.provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'flex',
      minHeight: '100vh',
      flexDirection: 'column',
    },
    container: {
      padding: '64px 0px',
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      [theme.breakpoints.down('md')]: {
        padding: '64px 40px',
      },
      [theme.breakpoints.only('xs')]: {
        padding: '32px 15px',
      },
    },
  }),
);

interface Props {
  children?: React.ReactNode;
  containerId?: string;
  heroElement?: React.ReactNode;
}

export const CrdMarketplacePage: React.FC<Props> = ({
  children,
  containerId,
  heroElement,
}: Props) => {
  const classes = useStyles();

  useEffect(() => {
    ScrollProvider.getInstance().scrollToTop({ smooth: false });
  }, []);

  return (
    <div className={classes.wrapper}>
      <HeaderContainer />
      {heroElement}
      <Container maxWidth="lg" className={classes.container} id={containerId}>
        {children}
      </Container>
      <CrdFooter />
    </div>
  );
};
