import { from, Observable, timer } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { MockConfig } from '../mocks/mocks.mappings';
import { MocksProvider } from '../mocks/mocks.provider';
import { Fetch } from './fetch.interface';

const defaultMockConfig: MockConfig = {
  delay: 1000,
};

export class MockedFetchService implements Fetch {
  fetch(path: string, init?: RequestInit | undefined): Observable<Response> {
    const method = (init && init.method) || 'GET';
    const mock = MocksProvider.getInstance().resolveMock(path, method);
    const mockConfig = { ...defaultMockConfig, ...mock.config };
    return timer(mockConfig.delay).pipe(flatMap(() => from(mock.handler())));
  }
}
