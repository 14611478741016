import { PaymentMethod, PaymentMethodType } from './payment-method.types';

export const findPaymentMethodByType = (
  paymentMethods: PaymentMethod[],
  type: PaymentMethodType,
): PaymentMethod | undefined => {
  return paymentMethods.find(paymentMethod => paymentMethod.type === type);
};

export const findPaymentMethodById = (
  paymentMethods: PaymentMethod[],
  id: string,
): PaymentMethod | undefined => {
  return paymentMethods.find(paymentMethod => paymentMethod.id === id);
};

export const createPaymentMethodByType = (
  paymentMethod: PaymentMethod,
  type: PaymentMethodType,
): PaymentMethod => {
  return { ...paymentMethod, type };
};

export const sortPaymentMethods = (
  paymentMethods: PaymentMethod[],
  sortMapping: PaymentMethodType[],
): PaymentMethod[] => {
  const paymentMethodsSorted: PaymentMethod[] = [];
  sortMapping.forEach((sortElement: PaymentMethodType) => {
    const findPaymentMethod = paymentMethods.find(
      paymentMethod => paymentMethod.type === sortElement,
    );
    if (findPaymentMethod) {
      paymentMethodsSorted.push(findPaymentMethod);
    }
  });
  return paymentMethodsSorted;
};
