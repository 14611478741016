import { PaymentMethod } from 'domain/payment-method/payment-method.types';

export interface Product {
  id: string;
  displayName: string;
  description: string;
  variations: ProductVariation[];
  defaultVariation: ProductVariation;
}

export enum MediaType {
  VIDEO = 'VIDEO',
  IMAGE = 'IMAGE',
}

export interface Media {
  source: string;
  thumbnail: string;
  type: MediaType;
}

export interface ProductVariation {
  id: string;
  sku: string;
  color: Color;
  capacity: string;
  paymentMethods: PaymentMethod[];
  enabled: boolean;
  media: Media[];
  minimumMonthlyPrice: string;
  sellerPrice: string;
}

export interface ProductConfiguration {
  color: Color;
  storage: string;
}

export interface ProductStorage {
  capacity: string;
  installment: string;
}

export interface ProductStorageAvailability {
  capacity: string;
  installment: string;
  enabled: boolean;
}

export interface ProductColorAvailability {
  color: Color;
  enabled: boolean;
}

export interface Color {
  code: string;
  nameLiteral: string;
}
