import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { CrdButton } from 'modules/shared/components/button/button.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.colors.neutral.N_12,
    },
    container: {
      backgroundImage: `url("${process.env.REACT_APP_STORE_PATH}/assets/images/hero-md.jpg")`,
      backgroundPosition: 'center center',
      backgroundSize: 'cover',
      [theme.breakpoints.only('xs')]: {
        backgroundImage: `url("${process.env.REACT_APP_STORE_PATH}/assets/images/hero-xs.jpg")`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    buttonContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.layout.columnGap,
      margin: '0 auto',
      maxWidth: '568px',
      padding: '289px 0 300px',
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
        gap: '16px',
        margin: '0 15px',
        padding: '300px 0 190px',
      },
    },
    button: {
      fontSize: '16px',
      lineHeight: '22px',
      boxShadow: 'none',
    },
    disabled: {
      opacity: theme.disabled.opacity,
      pointerEvents: 'none',
    },
  }),
);

interface Props {
  disabled?: boolean;
  onBuyClick: () => void;
  onHowItWorksClick: () => void;
}

export const CatalogHeroComponent: React.FC<Props> = ({
  disabled = false,
  onBuyClick,
  onHowItWorksClick,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div
          className={clsx(
            disabled && classes.disabled,
            classes.buttonContainer,
          )}
        >
          <CrdButton
            color="primary"
            size="large"
            className={classes.button}
            onClick={onBuyClick}
            data-testid="buy-button"
          >
            {t('CATALOG_HERO.BUTTON_BUY')}
          </CrdButton>
          <CrdButton
            color="secondary"
            size="large"
            variant="outlined"
            className={classes.button}
            onClick={onHowItWorksClick}
            data-testid="how-it-works-button"
          >
            {t('CATALOG_HERO.BUTTON_HOW_IT_WORKS')}
          </CrdButton>
        </div>
      </div>
    </div>
  );
};
