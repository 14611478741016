import React, { useEffect, useState } from 'react';
import { tap } from 'rxjs/operators';

import { getProduct } from 'api/product.api';
import { Product } from 'domain/product/product.types';
import { CrdProductDescriptionPlaceholder } from 'modules/product/components/product-description-placeholder.component';
import { CrdProductDescription } from 'modules/product/components/product-description.component';

import { CrdProductConfiguratorPlaceholder } from './product-configurator-placeholder.container';
import { CrdProductConfigurator } from './product-configurator.container';

interface ProductFormProps {
  productId: string;
}

export const CrdProduct: React.FC<ProductFormProps> = ({
  productId,
}: ProductFormProps) => {
  const [product, setProduct] = useState<Product>();

  useEffect(() => {
    getProduct(productId)
      .pipe(
        tap(product => {
          setProduct(product);
        }),
      )
      .subscribe();
  }, [productId]);

  return product ? (
    <div>
      <CrdProductConfigurator product={product} />
      <CrdProductDescription description={product.description} />
    </div>
  ) : (
    <div>
      <CrdProductConfiguratorPlaceholder />
      <CrdProductDescriptionPlaceholder />
    </div>
  );
};
