import { FaqSection } from './faq.types';

export const FAQ_SECTIONS: FaqSection[] = [
  {
    titleLiteral: 'FAQ.GENERAL.TITLE',
    uriLiteral: 'FAQ.GENERAL.URI',
    faqs: [
      {
        questionLiteral: 'FAQ.GENERAL.CONTENTS.0.QUESTION',
        answerLiteral: 'FAQ.GENERAL.CONTENTS.0.ANSWER',
        uriLiteral: 'FAQ.GENERAL.CONTENTS.0.URI',
      },
      {
        questionLiteral: 'FAQ.GENERAL.CONTENTS.1.QUESTION',
        answerLiteral: 'FAQ.GENERAL.CONTENTS.1.ANSWER',
        uriLiteral: 'FAQ.GENERAL.CONTENTS.1.URI',
      },
      {
        questionLiteral: 'FAQ.GENERAL.CONTENTS.2.QUESTION',
        answerLiteral: 'FAQ.GENERAL.CONTENTS.2.ANSWER',
        uriLiteral: 'FAQ.GENERAL.CONTENTS.2.URI',
      },
      {
        questionLiteral: 'FAQ.GENERAL.CONTENTS.3.QUESTION',
        answerLiteral: 'FAQ.GENERAL.CONTENTS.3.ANSWER',
        uriLiteral: 'FAQ.GENERAL.CONTENTS.3.URI',
      },
      {
        questionLiteral: 'FAQ.GENERAL.CONTENTS.4.QUESTION',
        answerLiteral: 'FAQ.GENERAL.CONTENTS.4.ANSWER',
        uriLiteral: 'FAQ.GENERAL.CONTENTS.4.URI',
      },
    ],
  },
  {
    titleLiteral: 'FAQ.PAYMENT.TITLE',
    uriLiteral: 'FAQ.PAYMENT.URI',
    faqs: [
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.0.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.0.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.0.URI',
      },
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.1.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.1.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.1.URI',
      },
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.2.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.2.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.2.URI',
      },
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.3.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.3.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.3.URI',
      },
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.4.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.4.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.4.URI',
      },
      {
        questionLiteral: 'FAQ.PAYMENT.CONTENTS.5.QUESTION',
        answerLiteral: 'FAQ.PAYMENT.CONTENTS.5.ANSWER',
        uriLiteral: 'FAQ.PAYMENT.CONTENTS.5.URI',
      },
    ],
  },
  {
    titleLiteral: 'FAQ.RETURN_CANCEL.TITLE',
    uriLiteral: 'FAQ.RETURN_CANCEL.URI',
    faqs: [
      {
        questionLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.0.QUESTION',
        answerLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.0.ANSWER',
        uriLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.0.URI',
      },
      {
        questionLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.1.QUESTION',
        answerLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.1.ANSWER',
        uriLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.1.URI',
      },
      {
        questionLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.2.QUESTION',
        answerLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.2.ANSWER',
        uriLiteral: 'FAQ.RETURN_CANCEL.CONTENTS.2.URI',
      },
    ],
  },
  {
    titleLiteral: 'FAQ.DELIVERY.TITLE',
    uriLiteral: 'FAQ.DELIVERY.URI',
    faqs: [
      {
        questionLiteral: 'FAQ.DELIVERY.CONTENTS.0.QUESTION',
        answerLiteral: 'FAQ.DELIVERY.CONTENTS.0.ANSWER',
        uriLiteral: 'FAQ.DELIVERY.CONTENTS.0.URI',
      },
      {
        questionLiteral: 'FAQ.DELIVERY.CONTENTS.1.QUESTION',
        answerLiteral: 'FAQ.DELIVERY.CONTENTS.1.ANSWER',
        uriLiteral: 'FAQ.DELIVERY.CONTENTS.1.URI',
      },
      {
        questionLiteral: 'FAQ.DELIVERY.CONTENTS.2.QUESTION',
        answerLiteral: 'FAQ.DELIVERY.CONTENTS.2.ANSWER',
        uriLiteral: 'FAQ.DELIVERY.CONTENTS.2.URI',
      },
    ],
  },
];
