import clsx from 'clsx';
import React from 'react';

import {
  createStyles,
  FormControl,
  FormControlLabel,
  makeStyles,
  Radio,
  RadioGroup,
  Theme,
} from '@material-ui/core';

import { PAYMENT_METHODS_TRACKING_EVENT_MAPPING } from 'domain/payment-method/payment-method.constants';
import { PaymentMethod } from 'domain/payment-method/payment-method.types';
import { findPaymentMethodById } from 'domain/payment-method/payment-method.utils';
import { TRACKING_EVENTS } from 'domain/tracking/tracking.constants';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';

import { CrdPaymentMethod } from './payment-method.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      cursor: 'pointer',
      border: `1px solid ${theme.colors.neutral.N_10}`,
      borderRadius: '4px',
      marginTop: '24px',
      outline: 'none',
    },
    selected: {
      borderColor: theme.colors.main.primary,
    },
    formControlRoot: {
      alignItems: 'flex-start',
      padding: '26px',
    },
    formControlLabel: {
      paddingTop: '9px',
    },
  }),
);

interface Props {
  onChange: (paymentMethodId: string) => void;
  paymentMethods: PaymentMethod[];
  current?: PaymentMethod;
}

export const CrdPaymentMethods: React.FC<Props> = ({
  paymentMethods,
  current,
  onChange,
}: Props) => {
  const classes = useStyles();
  const { trackEvent } = useTracking();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const paymentMethodId = (event.target as HTMLInputElement).value as string;
    onChange(paymentMethodId);
    const selectedPaymentMethod = findPaymentMethodById(
      paymentMethods,
      paymentMethodId,
    );

    if (selectedPaymentMethod) {
      trackEvent({
        event:
          TRACKING_EVENTS.SUMMARY_PAGE[
            PAYMENT_METHODS_TRACKING_EVENT_MAPPING[selectedPaymentMethod.type]
          ],
        label: `${paymentMethodId}, ${selectedPaymentMethod.installments}]`,
      });
    }
  };

  return (
    <RadioGroup
      aria-label="payment-methods"
      name="payment-methods"
      value={current ? current.id : ''}
      onChange={handleChange}
    >
      {paymentMethods.map((paymentMethod, index) => {
        return (
          <FormControl
            key={index}
            data-testid="payment-method"
            classes={{
              root: clsx(
                classes.container,
                current && current.id === paymentMethod.id && classes.selected,
              ),
            }}
          >
            <FormControlLabel
              classes={{
                root: classes.formControlRoot,
                label: classes.formControlLabel,
              }}
              value={paymentMethod.id}
              control={<Radio color="primary" />}
              label={
                <CrdPaymentMethod
                  paymentMethod={paymentMethod}
                  current={current}
                ></CrdPaymentMethod>
              }
            />
          </FormControl>
        );
      })}
    </RadioGroup>
  );
};
