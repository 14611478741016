import React from 'react';

import { Hidden, makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import {
  CrdContentLoader,
  PLACEHOLDER_BORDER_RADIUS,
} from 'modules/shared/components/content-loader/content-loader.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginBottom: '96px',
      gap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    title: {
      marginBottom: '8px',
      marginTop: 0,
    },
  }),
);

const titlePlaceholder = (
  <>
    <rect
      rx={PLACEHOLDER_BORDER_RADIUS}
      ry={PLACEHOLDER_BORDER_RADIUS}
      width="40%"
      height={32}
    />
    <rect
      y="50"
      rx={PLACEHOLDER_BORDER_RADIUS}
      ry={PLACEHOLDER_BORDER_RADIUS}
      width="70%"
      height={32}
    />
  </>
);

export const CrdProductConfiguratorPlaceholder: React.FC = () => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Hidden lgUp>
        <CrdContentLoader className={classes.title}>
          {titlePlaceholder}
        </CrdContentLoader>
      </Hidden>
      <div>
        <CrdContentLoader height={400}>
          <rect
            rx={PLACEHOLDER_BORDER_RADIUS}
            ry={PLACEHOLDER_BORDER_RADIUS}
            width={400}
            height={400}
          />
        </CrdContentLoader>
      </div>
      <div>
        <Hidden mdDown>
          <CrdContentLoader className={classes.title}>
            {titlePlaceholder}
          </CrdContentLoader>
        </Hidden>
      </div>
    </div>
  );
};
