import { Mock, mockMappings } from './mocks.mappings';

export class MocksService {
  resolveMock(path: string, method: string): Mock {
    const upperCasedMethod = method.toLocaleUpperCase();
    if (mockMappings[path] && mockMappings[path][upperCasedMethod]) {
      return mockMappings[path][upperCasedMethod];
    }
    throw new Error(`Mock not found for ${method}:${path}`);
  }
}
