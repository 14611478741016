import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Color, ProductColorAvailability } from 'domain/product/product.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    hint: {
      width: '32px',
      height: '32px',
      borderRadius: '50%',
    },
    name: {
      lineHeight: '24px',
      fontSize: '16px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      margin: 0,
    },
    title: {
      fontSize: '21px',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      lineHeight: '31px',
      marginTop: '32px',
      marginBottom: '8px',
    },
    optionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.layout.columnGap,
    },
    configuration: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: theme.palette.text.secondary,
      height: '112px',
      paddingTop: '24px',
      paddingBottom: '24px',
      cursor: 'pointer',
      outline: 'none',
    },
    selectedConfiguration: {
      borderColor: theme.colors.main.primary,
    },
  }),
);

interface Props {
  colorsAvailability: ProductColorAvailability[];
  selectedColor?: Color;
  onColorSelection: (color: Color) => void;
}

export const CrdColorConfiguration: React.FC<Props> = ({
  colorsAvailability,
  selectedColor,
  onColorSelection,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectColor = useCallback(
    (color: Color) => (): void => {
      onColorSelection(color);
    },
    [onColorSelection],
  );
  // TODO Extract common logic to a Configuration component
  return (
    <>
      <h4 className={classes.title}>
        {t('PRODUCT_FORM.CONFIGURATION.COLOR.TITLE')}
      </h4>
      <div className={classes.optionsContainer}>
        {colorsAvailability.map(colorAvailability => {
          return (
            <div
              className={clsx(
                !colorAvailability.enabled && classes.disabled,
                classes.configuration,
                selectedColor &&
                  selectedColor.code === colorAvailability.color.code &&
                  classes.selectedConfiguration,
              )}
              key={colorAvailability.color.code}
              onClick={selectColor(colorAvailability.color)}
              onKeyPress={selectColor(colorAvailability.color)}
              data-testid={
                colorAvailability.enabled ? 'color-box' : 'color-disabled'
              }
              role="link"
              tabIndex={0}
            >
              <div
                className={classes.hint}
                style={{ backgroundColor: colorAvailability.color.code }}
              ></div>
              <p className={classes.name}>
                {t(colorAvailability.color.nameLiteral)}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
