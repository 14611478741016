import { combineReducers, createStore } from 'redux';
import { devToolsEnhancer } from 'redux-devtools-extension';

import { catalogReducer } from './catalog/catalog.reducer';
import { paymentMethodReducer } from './paymentMethod/paymentMethod.reducer';
import { shoppingCartReducer } from './shoppingCart/shoppingCart.reducer';

const appReducer = combineReducers({
  catalog: catalogReducer,
  shoppingCart: shoppingCartReducer,
  paymentMethod: paymentMethodReducer,
});

export type AppState = ReturnType<typeof appReducer>;

export const store = createStore(
  appReducer,
  process.env.NODE_ENV !== 'production' ? devToolsEnhancer({}) : undefined,
);
