import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { LinkItem } from 'domain/navigation/navigation.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    link: {
      textDecoration: 'none',
    },
    primary: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '31px',
      padding: '0 15px',
      [theme.breakpoints.down('md')]: {
        lineHeight: '24px',
      },
    },
    secondary: {
      color: theme.colors.neutral.N_07,
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      padding: '0 5px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '12px',
        lineHeight: '18px',
      },
    },
  }),
);

interface Props {
  links: LinkItem[];
  variant: 'primary' | 'secondary';
}

export const CrdFooterLinks: React.FC<Props> = ({ links, variant }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {links.map(footerLink => {
        const child = (
          <span className={classes[variant]}>{t(footerLink.textLiteral)}</span>
        );

        if (!footerLink.isExternal) {
          return (
            <Link
              className={classes.link}
              to={footerLink.linksTo}
              key={footerLink.linksTo}
              {...footerLink.options}
            >
              {child}
            </Link>
          );
        }

        return (
          <a
            className={classes.link}
            href={footerLink.linksTo}
            key={footerLink.linksTo}
            {...footerLink.options}
          >
            {child}
          </a>
        );
      })}
    </>
  );
};
