import React from 'react';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    text: {
      color: theme.colors.neutral.N_02,
      fontSize: '14px',
      lineHeight: '31px',
      marginTop: '15px',
      [theme.breakpoints.only('xs')]: {
        fontSize: '14px',
        lineHeight: '21px',
        marginBottom: '0',
      },
    },
  }),
);

interface Props {
  text: string;
}

export const CrdFooterText: React.FC<Props> = ({ text }: Props) => {
  const classes = useStyles();

  return <div className={classes.text}> {text}</div>;
};
