import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      gap: `16px ${theme.layout.columnGap}`,
      marginTop: '16px',
      marginBottom: '72px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '2fr 1fr',
      },
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr 1fr',
        marginTop: '10px',
        marginBottom: '72px',
      },
    },
    text: {
      fontWeight: 'normal',
      fontSize: '14px',
      lineHeight: '21px',
      color: theme.palette.text.primary,
      margin: 0,
    },
    leftSide: {
      textAlign: 'end',
      [theme.breakpoints.only('xs')]: {
        textAlign: 'start',
      },
    },
    rightSide: {
      textAlign: 'end',
    },
    bold: {
      fontWeight: 'bold',
    },
  }),
);

interface Props {
  sellerPrice: string;
  interestPercentage?: string;
  frete?: string;
  totalPrice?: string;
}

export const CrdShoppingCartTotals: React.FC<Props> = ({
  sellerPrice,
  interestPercentage,
  frete,
  totalPrice,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.container}>
      <p className={clsx(classes.leftSide, classes.text)}>
        {t('SHOPPING_CART.TOTALS.PRESTIMO')}
      </p>
      <p className={clsx(classes.rightSide, classes.text)}>{sellerPrice}</p>
      <p className={clsx(classes.leftSide, classes.text)}>
        {t('SHOPPING_CART.TOTALS.SHIPMENT_COST')}
      </p>
      <p className={clsx(classes.rightSide, classes.text)}>
        {frete ? frete : t('SHOPPING_CART.TOTALS.EMPTY')}
      </p>
    </div>
  );
};
