import { from, Observable } from 'rxjs';

import { Fetch, FetchOptions } from './fetch.interface';

const defaultHeaders: HeadersInit = { 'Content-Type': 'application/json' };

const defaultOptions: FetchOptions = {
  useDefaultHeaders: true,
};

export class HttpFetchService implements Fetch {
  fetch(
    path: string,
    init?: RequestInit | undefined,
    options = defaultOptions,
  ): Observable<Response> {
    const headers = options.useDefaultHeaders ? defaultHeaders : {};

    const url = path.startsWith('http')
      ? path
      : `${process.env.REACT_APP_API_URL}${path}`;

    return from(
      fetch(url, {
        ...init,
        headers: init ? { ...headers, ...init.headers } : headers,
      }),
    );
  }
}
