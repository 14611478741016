import {
  Color,
  Media,
  ProductColorAvailability,
  ProductConfiguration,
  ProductStorage,
  ProductStorageAvailability,
  ProductVariation,
} from './product.types';

export const getColorsAvailabilityByVariation = (
  variations: ProductVariation[],
): ProductColorAvailability[] => {
  const productColorsAvailability: ProductColorAvailability[] = [];
  variations.forEach((variation: ProductVariation) => {
    const colorFound = productColorsAvailability.find(
      productColorAvailability =>
        productColorAvailability.color.code === variation.color.code,
    );

    if (!colorFound) {
      productColorsAvailability.push({
        color: variation.color,
        enabled: variation.enabled,
      });
      return;
    }

    if (!colorFound.enabled) {
      colorFound.enabled = variation.enabled;
    }
  });
  return productColorsAvailability;
};

export const getSortedColorsByAvailability = (
  variations: ProductVariation[],
): ProductColorAvailability[] => {
  const colorsAvailability = getColorsAvailabilityByVariation(variations);
  const colorsAvailable = colorsAvailability.filter(
    colorAvailability => colorAvailability.enabled,
  );

  const colorsNotAvailable = colorsAvailability.filter(
    colorAvailability => !colorAvailability.enabled,
  );

  return [...colorsAvailable, ...colorsNotAvailable];
};

export const getStorages = (
  variations: ProductVariation[],
): ProductStorage[] => {
  const allStorages = variations.map(productVariation => ({
    capacity: productVariation.capacity,
    installment: productVariation.minimumMonthlyPrice,
  }));

  const installmentToNumber = (installment: string): number =>
    parseFloat(installment.replace('R$', ''));
  const filteredStorages = new Map();

  // eslint-disable-next-line
  allStorages.map(storage => {
    const currentStorage = filteredStorages.get(storage.capacity);

    if (
      !currentStorage ||
      installmentToNumber(currentStorage.installment) >
        installmentToNumber(storage.installment)
    ) {
      filteredStorages.set(storage.capacity, storage);
    }
  });

  const result: ProductStorage[] = [];
  filteredStorages.forEach(value => result.push(value));

  return result.sort((a, b) => {
    const regex = /([0-9]*)/gm;
    const aMatch = a.capacity.match(regex);
    const bMatch = b.capacity.match(regex);

    if (aMatch && bMatch) {
      return Number(aMatch[0]) - Number(bMatch[0]);
    }

    return 0;
  });
};

export const getStoragesByColor = (
  variations: ProductVariation[],
  color: Color,
): string[] => {
  return variations
    .filter(variation => variation.color.code === color.code)
    .map(variation => variation.capacity);
};

export const getStoragesAvailabilityByColor = (
  enabledVariations: ProductVariation[],
  selectedColor: Color | undefined,
): ProductStorageAvailability[] => {
  return getStorages(enabledVariations).map<ProductStorageAvailability>(
    storageValue => ({
      capacity: storageValue.capacity,
      installment: storageValue.installment,
      enabled: !selectedColor
        ? false
        : !!getStoragesByColor(enabledVariations, selectedColor).find(
            storage => storage === storageValue.capacity,
          ),
    }),
  );
};

export const filterEnabledVariations = (
  variations: ProductVariation[],
): ProductVariation[] => {
  return variations.filter(
    variation => variation.enabled && variation.paymentMethods.length,
  );
};

export const findVariationByConfiguration = (
  variations: ProductVariation[],
  configuration: ProductConfiguration,
): ProductVariation => {
  const selectedVariation = variations.find(
    variation =>
      variation.enabled &&
      variation.color.code === configuration.color.code &&
      variation.capacity === configuration.storage,
  );
  if (!selectedVariation) {
    throw new Error('The selected variation could not be found.');
  }
  return selectedVariation;
};

export const findVariationMediaByColor = (
  variations: ProductVariation[],
  color: Color,
): Array<Media> => {
  const variation = variations.find(
    (variation: ProductVariation) =>
      variation.enabled && variation.color.code === color.code,
  );
  return variation ? variation.media : [];
};
