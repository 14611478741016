import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  CATALOG_CONTAINER_ID,
  CATALOG_HERO_DISPLAY_NAME,
} from 'domain/catalog/catalog.constants';
import { CatalogItem } from 'domain/catalog/catalog.types';
import { findCatalogItemByDisplayName } from 'domain/catalog/catalog.utils';
import { TRACKING_EVENTS } from 'domain/tracking/tracking.constants';
import { CatalogHeroComponent } from 'modules/catalog/components/catalog-hero/catalog-hero.component';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';
import { AppRoutes } from 'pages/app.routes';
import { AppState } from 'redux/store';
import { ScrollProvider } from 'services/scroll/scroll.provider';

export const CatalogHeroContainer: React.FC = () => {
  const history = useHistory();
  const { trackEvent } = useTracking();
  const [catalogHeroItem, setCatalogHeroItem] = useState<CatalogItem>();
  const catalogItems = useSelector<AppState, CatalogItem[]>(
    state => state.catalog.items,
  );

  useEffect(() => {
    setCatalogHeroItem(
      findCatalogItemByDisplayName(catalogItems, CATALOG_HERO_DISPLAY_NAME),
    );
  }, [catalogItems]);

  const onBuyClick = useCallback((): void => {
    catalogHeroItem &&
      trackEvent({
        event: TRACKING_EVENTS.CATALOG_PAGE.HeroButtonBuyClick,
        label: `${process.env.REACT_APP_STORE_PATH}/apple/${catalogHeroItem.id}`,
      });
    catalogHeroItem
      ? history.push(`/apple/${catalogHeroItem.id}`)
      : ScrollProvider.getInstance().scrollToElementById(CATALOG_CONTAINER_ID);
  }, [trackEvent, catalogHeroItem, history]);

  const onHowItWorksClick = useCallback((): void => {
    trackEvent({
      event: TRACKING_EVENTS.CATALOG_PAGE.HeroButtonHowItWorksClick,
      label: `${process.env.REACT_APP_STORE_PATH}/apple${AppRoutes.HowItWorksPage}`,
    });
    history.push(AppRoutes.HowItWorksPage);
  }, [trackEvent, history]);

  return (
    <CatalogHeroComponent
      onBuyClick={onBuyClick}
      onHowItWorksClick={onHowItWorksClick}
    />
  );
};
