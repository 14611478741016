import clsx from 'clsx';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSplit } from 'react-splitio';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';

import { CircularProgress, Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import {
  PaymentMethod,
  PaymentMethodType,
} from 'domain/payment-method/payment-method.types';
import {
  findPaymentMethodById,
  findPaymentMethodByType,
} from 'domain/payment-method/payment-method.utils';
import { roundStringValue } from 'domain/shared/shared.utils';
import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';
import { TRACKING_EVENTS } from 'domain/tracking/tracking.constants';
import { CrdButton } from 'modules/shared/components/button/button.component';
import { CrdNotification } from 'modules/shared/components/notification/notification.component';
import { NotificationType } from 'modules/shared/components/notification/notification.types';
import { CrdTranslator } from 'modules/shared/components/translator/translator.component';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';
import { AppState } from 'redux/store';

import { CrdPaymentMethods } from '../components/payment-methods.component';
import { CrdShoppingCartEmpty } from '../components/shopping-cart-empty.component';
import { CrdShoppingCartTotals } from '../components/shopping-cart-totals.component';
import { useSummaryCommands } from '../summary.commands';
import { CrdShoppingCartContainer } from './shopping-cart.container';

const SPINNER_SIZE = 22;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    bottomContainer: {
      margin: '48px 0',
    },
    bottomHeadline: {
      marginTop: '40px',
    },
    disabled: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    paymentAndTotalsContainer: {
      display: 'grid',
      gridTemplateColumns: '3fr 2fr',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gridRowGap: '32px',
      },
    },
    totalsContainer: {
      alignSelf: 'end',
      [theme.breakpoints.down('md')]: {
        gridColumnStart: 1,
      },
    },
    buttonContainer: {
      display: 'grid',
      gridTemplateColumns: '9fr 5fr',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '3fr 1fr',
      },
      [theme.breakpoints.down('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
    button: {
      height: '40px',
      padding: 0,
      gridColumnStart: 2,
      [theme.breakpoints.down('xs')]: {
        gridColumnStart: 1,
      },
    },
    spinner: {
      color: theme.colors.neutral.N_18,
    },
    notificationContainer: {
      marginTop: '32px',
    },
    legalTextContainer: {
      marginTop: '40px',
    },
    legalText: {
      color: theme.palette.text.primary,
      fontWeight: 'normal',
      fontSize: '12px',
    },
  }),
);

export const CrdSummaryContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const { addSelectedPaymentMethodType, createOrder } = useSummaryCommands();
  const [navigateToPayroll] = useSplit('MRKT_MX_FRONT_NAVIGATE_PAYROLL');

  const shoppingCartItems = useSelector<AppState, ShoppingCartItem[]>(
    state => state.shoppingCart.items,
  );

  const paymentMethodType = useSelector<AppState, PaymentMethodType>(
    state => state.paymentMethod.type,
  );

  const [currentPaymentMethod, setCurrentPaymentMethod] = React.useState<
    PaymentMethod | undefined
  >(
    findPaymentMethodByType(
      shoppingCartItems[0] ? shoppingCartItems[0].variation.paymentMethods : [],
      paymentMethodType,
    ),
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [orderError, setOrderError] = useState<boolean>(false);

  const handlePaymentMethodChange = (id: string): void => {
    setCurrentPaymentMethod(
      findPaymentMethodById(shoppingCartItems[0].variation.paymentMethods, id),
    );
  };

  const confirmOrder = (): void => {
    setLoading(true);
    setOrderError(false);
    currentPaymentMethod &&
      forkJoin(
        createOrder(
          shoppingCartItems[0].variation.sku,
          currentPaymentMethod.id,
        ),
        addSelectedPaymentMethodType(currentPaymentMethod.type),
      )
        .pipe(
          map((data: any) => {
            if (navigateToPayroll === 'on') {
              window.location.href =
                process.env.REACT_APP_PAYROLL_URL +
                `?token=${data[0]['token']}` +
                `&stepper=${data[0]['stepperId']}`;
            } else {
              console.log('unable to access to payroll');
            }
          }),
        )
        .subscribe(
          () => {},
          () => {
            setLoading(false);
            setOrderError(true);
          },
          () => {
            setLoading(false);
          },
        );

    trackEvent({
      event: TRACKING_EVENTS.SUMMARY_PAGE.ConfirmationClick,
    });
  };

  return (
    <>
      {shoppingCartItems.length > 0 && (
        <>
          <CrdShoppingCartContainer
            shoppingCartItems={shoppingCartItems}
          ></CrdShoppingCartContainer>
          <div className={classes.bottomContainer}>
            <Typography
              variant="h3"
              component="h3"
              className={classes.bottomHeadline}
            >
              {t('PAYMENT_METHODS.HEADLINE')}
            </Typography>
            <div className={classes.paymentAndTotalsContainer}>
              <CrdPaymentMethods
                paymentMethods={shoppingCartItems[0].variation.paymentMethods}
                current={currentPaymentMethod}
                onChange={handlePaymentMethodChange}
              />
              <div className={classes.totalsContainer}>
                <CrdShoppingCartTotals
                  sellerPrice={shoppingCartItems[0].variation.sellerPrice}
                  interestPercentage={
                    currentPaymentMethod &&
                    roundStringValue(currentPaymentMethod.interestPercentage)
                  }
                  frete={
                    currentPaymentMethod &&
                    t('SHOPPING_CART.TOTALS.SHIPMENT_COST_VALUE')
                  }
                  totalPrice={
                    currentPaymentMethod && currentPaymentMethod.totalPrice
                  }
                />
                <div
                  className={clsx(
                    !currentPaymentMethod && classes.disabled,
                    classes.buttonContainer,
                  )}
                >
                  <CrdButton
                    onClick={confirmOrder}
                    className={classes.button}
                    data-testid="confirm-payment"
                    disabled={loading}
                  >
                    {loading ? (
                      <CircularProgress
                        className={classes.spinner}
                        size={SPINNER_SIZE}
                      />
                    ) : (
                      t('SHOPPING_CART.CREATE_ORDER')
                    )}
                  </CrdButton>
                </div>
                {orderError && (
                  <div className={classes.notificationContainer}>
                    <CrdNotification
                      type={NotificationType.ERROR}
                      textLiteral={t('SHOPPING_CART.CREATE_ORDER_ERROR')}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className={classes.legalTextContainer}>
              <Typography className={classes.legalText}>
                {t('SUMMARY_PAGE.LEGAL_TEXT_1')}
              </Typography>
              <Typography className={classes.legalText}>
                {t('SUMMARY_PAGE.LEGAL_TEXT_2')}
              </Typography>
              <Typography className={classes.legalText}>
                <CrdTranslator literal={'SUMMARY_PAGE.LEGAL_TEXT_3'} />
              </Typography>
            </div>
          </div>
        </>
      )}

      {shoppingCartItems.length === 0 && <CrdShoppingCartEmpty />}
    </>
  );
};
