import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  AppBar,
  Container,
  createStyles,
  CssBaseline,
  Drawer,
  Hidden,
  IconButton,
  Theme,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/styles';

import { HEADER_LINKS } from 'domain/navigation/navigation.constants';
import { HEADER as HEADER_TRACKING } from 'domain/tracking/tracking.constants';
import { MarketplaceLogoComponent } from 'modules/shared/components/marketplace-logo/marketplace-logo.component';
import { NavbarLinkComponent } from 'modules/shared/components/navbar-link/navbar-link.component';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      background: theme.colors.neutral.N_18,
      // This z-index should always be higher than the paper element
      // as we want the drawer to "slide i" from under the AppBar.
      zIndex: 2500,
    },
    toolbar: {
      paddingLeft: 0,
      paddingRight: 0,
      display: 'grid',
      gridTemplateColumns: '7fr 5fr',
      gridColumnGap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 1fr',
      },
    },
    logo: {
      [theme.breakpoints.up('lg')]: {
        marginRight: '12em',
      },
    },
    iconButton: {
      justifyContent: 'flex-end',
      '&:hover': {
        background: 'none',
      },
      '&:focus': {
        background: 'none',
      },
    },
    menuTitle: {
      color: theme.colors.neutral.N_07,
      fontSize: '16px',
      fontWeight: 'bold',
      lineHeight: '28px',
      textTransform: 'uppercase',
    },
    navBarContainer: {
      textAlign: 'right',
      display: 'flex',
      justifyContent: 'space-between',
    },
    drawerPaper: {
      bottom: '0',
      top: '56px',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    link: {
      [theme.breakpoints.down('xs')]: {
        display: 'block',
        padding: '20px 15px',
      },
    },
  }),
);

export const HeaderContainer: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const currentPath = history.location.pathname;

  const handleDrawer = (): void => {
    setOpen(isOpen => {
      trackEvent({
        event: HEADER_TRACKING.SideMenuVisualization,
        label: currentPath,
      });
      return !isOpen;
    });
  };

  const handleClick = useCallback(
    (trackingSection: string): void => {
      trackingSection.length &&
        trackEvent({
          event: HEADER_TRACKING[trackingSection],
          label: currentPath,
        });
    },
    [currentPath, trackEvent],
  );

  return (
    <div className={`${classes.root} Header`}>
      <CssBaseline />
      <AppBar className={classes.appBar} position="static" elevation={0}>
        <Container maxWidth="lg">
          <Toolbar className={classes.toolbar}>
            <MarketplaceLogoComponent width={152} className={classes.logo} />
            <Hidden smUp>
              <IconButton
                aria-label="open drawer"
                classes={{ root: classes.iconButton }}
                onClick={handleDrawer}
                disableRipple={true}
              >
                {!open && (
                  <>
                    <MenuIcon />
                    <Typography
                      className={classes.menuTitle}
                      variant="body1"
                      component="p"
                    >
                      {t('MENU.TITLE')}
                    </Typography>
                  </>
                )}
                {open && <CloseIcon />}
              </IconButton>
            </Hidden>
            <Hidden xsDown>
              <div className={classes.navBarContainer}>
                {HEADER_LINKS.map(headerLink => (
                  <NavbarLinkComponent
                    key={headerLink.linksTo}
                    linkItem={headerLink}
                    onClick={handleClick}
                  ></NavbarLinkComponent>
                ))}
              </div>
            </Hidden>
          </Toolbar>
        </Container>
      </AppBar>
      <Hidden lgUp>
        <Drawer
          variant="persistent"
          anchor="top"
          open={open}
          transitionDuration={600}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          {HEADER_LINKS.map(headerLink => (
            <NavbarLinkComponent
              key={headerLink.linksTo}
              linkItem={headerLink}
              className={classes.link}
              onClick={handleClick}
            ></NavbarLinkComponent>
          ))}
        </Drawer>
      </Hidden>
      <div className={classes.drawerHeader} />
    </div>
  );
};
