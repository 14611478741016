import * as smoothscroll from 'smoothscroll-polyfill';

// Add support to Safari and Internet Explorer
smoothscroll.polyfill();

type Options = {
  smooth?: boolean;
};

const defaultOptions: Options = { smooth: true };

export class ScrollService {
  scrollToElement(element: Element, options?: Options): void {
    const selectedOptions = { ...defaultOptions, ...options };
    element.scrollIntoView({
      behavior: selectedOptions.smooth ? 'smooth' : 'auto',
    });
  }

  scrollToElementById(id: string, options?: Options): void {
    const element = document.getElementById(id);
    if (element) {
      this.scrollToElement(element, options);
    }
  }

  scrollToTop(options?: Options): void {
    const selectedOptions = { ...defaultOptions, ...options };
    window.scrollTo({
      top: 0,
      behavior: selectedOptions.smooth ? 'smooth' : 'auto',
    });
  }
}
