export const mockedCatalog = [
  {
    id: 'd140c6cb-e1de-451e-b25f-c16b0562a045',
    displayName: 'iPhone 11 Pro',
    image:
      'https://marketplace-api-prod-product-catalog.s3.amazonaws.com/homepage/iphone11_pro.jpg',
    minimumMonthlyPrice: 'R$ 229,90',
  },
  {
    id: 'a578167f-501a-4123-9158-cdbf7393e025',
    displayName: 'iPhone 11',
    image:
      'https://marketplace-api-prod-product-catalog.s3.amazonaws.com/homepage/iphone11.jpg',
    minimumMonthlyPrice: 'R$ 145,80',
  },
  {
    id: '4082c65f-fd24-417c-8a0f-bf5bd9e8c3f9',
    displayName: 'iPhone XR',
    image:
      'https://marketplace-api-prod-product-catalog.s3.amazonaws.com/homepage/iphone_xr.jpg',
    minimumMonthlyPrice: 'R$ 149,90',
  },
  {
    id: 'b8755000-403d-4247-8c7c-6a6548d01f15',
    displayName: 'iPhone 8 Plus',
    image:
      'https://marketplace-api-prod-product-catalog.s3.amazonaws.com/homepage/iphone8_plus.jpg',
    minimumMonthlyPrice: 'R$ 149,90',
  },
  {
    id: 'b4dd50ed-b132-4538-9f0f-d8f895673aed',
    displayName: 'iPhone 8',
    image:
      'https://marketplace-api-prod-product-catalog.s3.amazonaws.com/homepage/iphone8.jpg',
    minimumMonthlyPrice: 'R$ 129,90',
  },
];

export const getCatalogMock = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedCatalog)));
  });
};
