import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { HowItWorksItem } from 'domain/how-it-works/how-it-works.types';
import { CrdTranslator } from 'modules/shared/components/translator/translator.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '3fr 5fr',
      columnGap: theme.layout.columnGap,
      alignItems: 'center',
      minHeight: '225px',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr 2fr',
      },
      [theme.breakpoints.only('xs')]: {
        minHeight: '48px',
        gridTemplateColumns: '1fr',
      },
    },
    number: {
      color: '#23DB9B',
      textAlign: 'center',
      fontSize: '40px',
      lineHeight: '72px',
      [theme.breakpoints.only('xs')]: {
        lineHeight: '48px',
        fontSize: '32px',
      },
    },
    textContent: {
      [theme.breakpoints.only('xs')]: {
        textAlign: 'center',
      },
    },
    title: {
      lineHeight: '48px',
      [theme.breakpoints.only('xs')]: {
        lineHeight: '24px',
        fontSize: '16px',
      },
    },
    description: {
      lineHeight: '24px',
      fontWeight: 'normal',
      [theme.breakpoints.only('xs')]: {
        lineHeight: '21px',
        fontSize: '14px',
      },
    },
    dividerContainer: {
      display: 'flex',
      justifyContent: 'flex-start',
      [theme.breakpoints.only('xs')]: {
        justifyContent: 'center',
      },
    },
    divider: {
      borderRadius: '3px',
      backgroundColor: '#23DB9B',
      width: '40px',
      height: '5px',
      margin: '8px 0 16px 0',
    },
  }),
);

export interface Props {
  className: string;
  item: HowItWorksItem;
}
export const CrdHowItWorksItem: React.FC<Props> = ({
  className,
  item,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={clsx(className, classes.container)}>
      <Typography className={classes.number} variant="h3" component="p">
        {item.hideNumber ? '' : item.number}
      </Typography>
      <div className={classes.textContent}>
        <Typography className={classes.title} variant="h4" component="h3">
          {t(item.text.titleLiteral)}
        </Typography>
        <Typography
          className={classes.description}
          variant="body1"
          component="p"
        >
          <CrdTranslator literal={item.text.descriptionLiteral} />
        </Typography>
      </div>
    </div>
  );
};
