import { AppRoutes } from 'pages/app.routes';

import { LinkItem } from './navigation.types';

export const HEADER_LINKS: LinkItem[] = [
  {
    linksTo: AppRoutes.CatalogPage,
    textLiteral: 'HEADER.LINK.PRODUCTS',
    options: { trackingSection: 'Products' },
  },
  {
    linksTo: AppRoutes.HowItWorksPage,
    textLiteral: 'HEADER.LINK.HOW_DOES_IT_WORK',
    options: { trackingSection: 'HowItWorks' },
  },
  {
    linksTo: AppRoutes.FaqPage,
    textLiteral: 'HEADER.LINK.HELP',
    options: { trackingSection: 'FAQ' },
  },
];

export const FOOTER_LINKS: LinkItem[] = [
  { linksTo: AppRoutes.CatalogPage, textLiteral: 'FOOTER.LINK.PRODUCTS' },
  {
    linksTo: AppRoutes.HowItWorksPage,
    textLiteral: 'FOOTER.LINK.HOW_DOES_IT_WORK',
  },
  { linksTo: AppRoutes.FaqPage, textLiteral: 'FOOTER.LINK.HELP' },
];

export const FOOTER_SECONDARY_LINKS: LinkItem[] = [
  {
    linksTo: AppRoutes.TermsAndConditionsPage,
    textLiteral: 'FOOTER.SECONDARY_LINK.TERMS_AND_CONDITIONS',
  },
  {
    linksTo: 'https://www.creditas.com/mx/legal/aviso-privacidad',
    textLiteral: 'FOOTER.SECONDARY_LINK.PRIVACY_POLICY',
    isExternal: true,
    options: {
      target: '_blank',
      rel: 'noopener',
    },
  },
];
