import { ScrollService } from './scroll.service';

export class ScrollProvider {
  private static instance: ScrollService;

  private constructor() {}

  static getInstance(): ScrollService {
    if (!ScrollProvider.instance) {
      ScrollProvider.instance = new ScrollService();
    }
    return ScrollProvider.instance;
  }
}
