import React from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CrdMarketplacePage } from '../misc/marketplace.page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '72px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '60px',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
  }),
);

export const TermsAndConditionsPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <CrdMarketplacePage>
      <Typography className={classes.title} variant="h2" component="h1">
        {t('TERMS_AND_CONDITIONS.HEADLINE')}
      </Typography>

      {/* TODO: Rework when we add CrdTranslator component */}
      <div
        dangerouslySetInnerHTML={{ __html: t('TERMS_AND_CONDITIONS.CONTENT') }}
      />
    </CrdMarketplacePage>
  );
};
