import {Observable, throwError} from 'rxjs';
import { catchError, flatMap } from 'rxjs/operators';

import { FetchProvider } from 'services/http/fetch.provider';

export const createOrder = (sku: string, priceId: string): Observable<any> => {
  return FetchProvider.getInstance()
    .fetch(`${process.env.REACT_APP_CREATE_ORDER_URL}`, {
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify({ productId: sku, offerId: priceId }),
    })
    .pipe(
      flatMap(res => {
        const token = res.headers.get('x-authorization');
        if (token) {
          return res.json().then(x => ({...x, token}));
        } else {
          return throwError({});
        }
      }),
      catchError(() => throwError({})),
    );
};
