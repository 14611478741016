import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { createOrder as createOrderApi } from 'api/payroll.api';
import { PaymentMethodType } from 'domain/payment-method/payment-method.types';
import { addPaymentMethodType } from 'redux/paymentMethod/paymentMethod.actions';
import { removeShoppingCartItem } from 'redux/shoppingCart/shoppingCart.actions';
import { store } from 'redux/store';

interface SummaryCommands {
  addSelectedPaymentMethodType: (
    paymentMethodType: PaymentMethodType,
  ) => Observable<undefined>;
  removeProductFromShoppingCart: () => Observable<undefined>;
  createOrder: (sku: string, priceId: string) => Observable<string>;
}

const addSelectedPaymentMethodType = (
  paymentMethodType: PaymentMethodType,
): Observable<undefined> => {
  store.dispatch(addPaymentMethodType(paymentMethodType));
  return of(undefined);
};

const removeProductFromShoppingCart = (): Observable<undefined> =>
  of(undefined).pipe(tap(() => store.dispatch(removeShoppingCartItem())));

const createOrder = (sku: string, priceId: string): Observable<string> => createOrderApi(sku, priceId);

export const useSummaryCommands = (): SummaryCommands => {
  return {
    addSelectedPaymentMethodType,
    removeProductFromShoppingCart,
    createOrder,
  };
};
