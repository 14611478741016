import React from 'react';

import {
  CrdContentLoader,
  PLACEHOLDER_BORDER_RADIUS,
} from 'modules/shared/components/content-loader/content-loader.component';

const LABEL_WIDTH_PERCENTAGE = 60;

export const CrdCatalogItemPlaceholderComponent: React.FC = () => (
  <div>
    <CrdContentLoader viewBox="0 0 400 400" height={400}>
      <rect
        rx={PLACEHOLDER_BORDER_RADIUS}
        ry={PLACEHOLDER_BORDER_RADIUS}
        width={400}
        height={400}
      />
    </CrdContentLoader>

    <CrdContentLoader viewBox="0 0 400 100" height={400}>
      <rect
        x={`${(100 - LABEL_WIDTH_PERCENTAGE) / 2}%`}
        y={24}
        rx={PLACEHOLDER_BORDER_RADIUS}
        ry={PLACEHOLDER_BORDER_RADIUS}
        width={`${LABEL_WIDTH_PERCENTAGE}%`}
        height={32}
      />
    </CrdContentLoader>
  </div>
);
