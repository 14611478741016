import React, { useEffect } from 'react';

import {
  TRACKING_TITLES,
  TrackingAction,
  TrackingCategory,
} from 'domain/tracking/tracking.constants';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';
import { CrdSummaryContainer } from 'modules/summary/containers/summary.container';
import { AppRoutes } from 'pages/app.routes';

import { CrdMarketplacePage } from '../misc/marketplace.page';

export const SummaryPage: React.FC = () => {
  const { trackPageLoad } = useTracking();

  useEffect(() => {
    trackPageLoad({
      page: {
        category: TrackingCategory.SUMMARY,
        action: TrackingAction.SUMMARY_PAGE_LOAD,
        title: TRACKING_TITLES.SUMMARY_PAGE,
        path: AppRoutes.SummaryPage,
      },
    });
  }, [trackPageLoad]);
  return (
    <CrdMarketplacePage>
      <CrdSummaryContainer />
    </CrdMarketplacePage>
  );
};
