import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import { createStyles, Hidden, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FaqSection } from 'domain/faq/faq.types';
import {
  findFaqIndexByUri,
  getSection,
  getSections,
} from 'domain/faq/faq.utils';
import { AppRoutes } from 'pages/app.routes';

import { CrdFaqDropDown } from '../components/drop-down/faq-drop-down.component';
import { CrdFaqTab } from '../components/tab/faq-tab.component';
import { CrdExpansionPanelList } from './expansion-panel-list.container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    selector: {
      marginBottom: '64px',
      justifyContent: 'center',
      [theme.breakpoints.down('md')]: {
        marginBottom: '48px',
      },
      [theme.breakpoints.only('xs')]: {
        marginBottom: '16px',
        justifyContent: 'unset',
      },
    },
    faqContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 10fr 1fr',
      columnGap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    panelList: {
      gridColumnStart: '2',
      [theme.breakpoints.down('md')]: {
        gridColumnStart: '1',
      },
    },
  }),
);

export const FaqContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const sections = getSections();
  const { t } = useTranslation();

  const [selectedSection, setSelectedSection] = useState<FaqSection>(
    sections[0],
  );

  const [selectedFaqIndex, setSelectedFaqIndex] = useState<
    number | undefined
  >();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const sectionUri = queryParams.get('section');
    setSelectedSection(getSection(sectionUri));

    const faqUri = queryParams.get('faq');
    setSelectedFaqIndex(findFaqIndexByUri(selectedSection.faqs, faqUri));
  }, [location.search, selectedSection.faqs]);

  const changeSection = (section: FaqSection): void => {
    const queryParams = new URLSearchParams({
      section: t(section.uriLiteral),
    });
    history.push(`${AppRoutes.FaqPage}?${queryParams.toString()}`);
  };

  const changeFaq = (faqIndex: number): void => {
    const selectedFaq = selectedSection.faqs[faqIndex];

    const currentQueryParams = new URLSearchParams(location.search);

    const newQueryParams = new URLSearchParams({
      section: t(selectedSection.uriLiteral),
      faq: t(selectedFaq.uriLiteral || ''),
    });

    if (
      !selectedFaq.uriLiteral ||
      currentQueryParams.get('faq') === t(selectedFaq.uriLiteral)
    ) {
      newQueryParams.delete('faq');
    }

    history.push(`${AppRoutes.FaqPage}?${newQueryParams.toString()}`);
  };

  return (
    <>
      <Hidden xsDown>
        <CrdFaqTab
          className={classes.selector}
          currentSelected={selectedSection}
          onSelect={changeSection}
          sections={sections}
        ></CrdFaqTab>
      </Hidden>
      <Hidden smUp>
        <CrdFaqDropDown
          className={classes.selector}
          currentSelected={selectedSection}
          onSelect={changeSection}
          sections={sections}
        ></CrdFaqDropDown>
      </Hidden>
      <div className={classes.faqContainer}>
        <CrdExpansionPanelList
          className={classes.panelList}
          faqs={selectedSection.faqs}
          expandedItemIndex={selectedFaqIndex}
          onItemClick={changeFaq}
        />
      </div>
    </>
  );
};
