import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  TRACKING_TITLES,
  TrackingAction,
  TrackingCategory,
} from 'domain/tracking/tracking.constants';
import { CrdProduct } from 'modules/product/containers/product.container';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';

import { CrdMarketplacePage } from '../misc/marketplace.page';

export const ProductPage: React.FC = () => {
  const { id } = useParams();
  const { trackPageLoad } = useTracking();

  useEffect(() => {
    id &&
      trackPageLoad({
        page: {
          category: TrackingCategory.PRODUCT_PAGE,
          action: TrackingAction.PRODUCT_PAGE_LOAD,
          title: TRACKING_TITLES.PRODUCT_PAGE,
          path: `/apple/${id}`,
        },
      });
  }, [trackPageLoad, id]);
  return (
    <CrdMarketplacePage>
      {id && <CrdProduct productId={id} />}
    </CrdMarketplacePage>
  );
};
