import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headline: {
      textAlign: 'center',
      lineHeight: '72px',
      marginBottom: '64px',
      [theme.breakpoints.down('md')]: {
        fontSize: '32px',
        lineHeight: '48px',
        marginBottom: '16px',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    image: {
      '& img': {
        maxWidth: '100%',
        height: 'auto',
      },
    },
  }),
);

interface Props {
  description: string;
}

export const CrdProductDescription: React.FC<Props> = ({
  description,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Typography className={classes.headline} variant="h2" component="h2">
        {t('PRODUCT_DESCRIPTION.HEADLINE')}
      </Typography>
      <div
        className={classes.image}
        dangerouslySetInnerHTML={{ __html: description }}
      ></div>
    </>
  );
};
