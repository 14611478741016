import React from 'react';

import { createStyles, Hidden, makeStyles, Theme } from '@material-ui/core';

import { CrdMarketplacePage } from '../misc/marketplace.page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    image: {
      maxWidth: '100%',
      height: 'auto',
    },
  }),
);

export const IphoneComparisonPage: React.FC = () => {
  const classes = useStyles();

  return (
    <CrdMarketplacePage>
      <Hidden smDown>
        <img
          className={classes.image}
          alt="iPhone comparison"
          src={`${process.env.REACT_APP_STORE_PATH}/assets/images/product-comparison.jpg`}
        />
      </Hidden>
      <Hidden mdUp>
        <img
          className={classes.image}
          alt="iPhone comparison"
          src={`${process.env.REACT_APP_STORE_PATH}/assets/images/product-comparison-mobile.jpg`}
        />
      </Hidden>
    </CrdMarketplacePage>
  );
};
