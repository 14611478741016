import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import { PaymentMethod } from 'domain/payment-method/payment-method.types';
import { CrdTranslator } from 'modules/shared/components/translator/translator.component';
import { AppRoutes } from 'pages/app.routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      border: `1px solid ${theme.colors.neutral.N_10}`,
      borderRadius: '4px',
      marginTop: '40px',
      padding: '32px',
      alignItems: 'center',
    },
    title: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'bold',
    },
    description: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      '& a': {
        color: theme.colors.neutral.N_07,
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
);

interface Props {
  paymentMethod: PaymentMethod;
  current?: PaymentMethod;
}

export const CrdPaymentMethod: React.FC<Props> = ({
  paymentMethod,
  current,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <>
      <div className={classes.title}>
        <CrdTranslator
          literal={`PAYMENT_METHODS.${paymentMethod.type}.TITLE`}
          values={{
            monthlyPrice: paymentMethod.monthlyPrice,
            balloonPrice: paymentMethod.balloonPrice,
            installments: paymentMethod.installments,
            years: paymentMethod.installments / 24, // Is is divided by 24 because they pay 2 installments by month
          }}
        />
      </div>
      {current && paymentMethod.type === current.type && (
        <div className={classes.description}>
          <CrdTranslator
            literal={`PAYMENT_METHODS.${paymentMethod.type}.DESCRIPTION`}
            values={{
              balloonConditionsUri: `${process.env.REACT_APP_STORE_PATH}${
                AppRoutes.FaqPage
              }?${new URLSearchParams({
                section: t('FAQ.PAYMENT.URI'),
                faq: t('FAQ.PAYMENT.CONTENTS.2.URI'),
              }).toString()}`,
              refundConditionsUri: `${process.env.REACT_APP_STORE_PATH}${
                AppRoutes.FaqPage
              }?${new URLSearchParams({
                section: t('FAQ.PAYMENT.URI'),
                faq: t('FAQ.PAYMENT.CONTENTS.3.URI'),
              }).toString()}`,
            }}
          />
        </div>
      )}
    </>
  );
};
