import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  literal: string;
  values?: object;
}

export const CrdTranslator: React.FC<Props> = ({ literal, values }: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <div dangerouslySetInnerHTML={{ __html: t(literal, values) }}></div>
    </React.Fragment>
  );
};
