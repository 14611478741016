import TagManager from 'react-gtm-module';

export class CrdTagManager {
  static initialize(): void {
    if (process.env.REACT_APP_GTM_ID) {
      TagManager.initialize({
        gtmId: process.env.REACT_APP_GTM_ID,
      });
    }
  }
}
