import React, { useEffect, useState } from 'react';

import {
  Color,
  ProductColorAvailability,
  ProductVariation,
} from 'domain/product/product.types';
import {
  filterEnabledVariations,
  findVariationByConfiguration,
  getSortedColorsByAvailability,
  getStoragesAvailabilityByColor,
} from 'domain/product/product.utils';
import { CrdColorConfiguration } from 'modules/product/components/product-color-configuration.component';
import { CrdStorageConfiguration } from 'modules/product/components/product-storage-configuration.component';

interface Props {
  variations: ProductVariation[];
  onChangeColor: (color: Color) => void;
  onVariationSelection: (variation: ProductVariation | undefined) => void;
}

export const CrdProductVariationSelector: React.FC<Props> = ({
  variations,
  onChangeColor,
  onVariationSelection,
}: Props) => {
  const [selectedColor, setSelectedColor] = useState<Color>();
  const [selectedStorage, setSelectedStorage] = useState<string>();
  const handleColorChange = (color: Color): void => {
    setSelectedStorage(undefined);
    setSelectedColor(color);
    onVariationSelection(undefined);
    onChangeColor(color);
  };

  const [colorsByAvailability, setColorsByAvailability] = useState<
    ProductColorAvailability[]
  >([]);

  const [enabledVariations, setEnabledVariations] = useState<
    ProductVariation[]
  >([]);

  useEffect(() => {
    setColorsByAvailability(getSortedColorsByAvailability(variations));
    setEnabledVariations(filterEnabledVariations(variations));
  }, [variations]);

  return (
    <>
      <CrdColorConfiguration
        colorsAvailability={colorsByAvailability}
        selectedColor={selectedColor}
        onColorSelection={handleColorChange}
      />

      <CrdStorageConfiguration
        disabled={!selectedColor}
        storages={getStoragesAvailabilityByColor(
          enabledVariations,
          selectedColor,
        )}
        selectedStorage={selectedStorage}
        onStorageSelection={(storage: string): void => {
          setSelectedStorage(storage);
          if (selectedColor) {
            onVariationSelection(
              findVariationByConfiguration(variations, {
                color: selectedColor,
                storage,
              }),
            );
          }
        }}
      />
    </>
  );
};
