import { CatalogItem } from './catalog.types';

export const findCatalogItemByDisplayName = (
  catalogItems: CatalogItem[],
  displayName: string,
): CatalogItem | undefined =>
  catalogItems.find(
    catalogItem =>
      catalogItem.displayName.toLocaleUpperCase() ===
      displayName.toLocaleUpperCase(),
  );
