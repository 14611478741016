import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import { createStyles, Hidden, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Media, MediaType } from 'domain/product/product.types';

import { CrdVariationMedia } from './product-variation-media.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    media: {
      [theme.breakpoints.down('md')]: {
        gridRowStart: '1',
      },
    },
  }),
);

interface Props {
  media: Array<Media>;
  mediaSelectedIndex: number;
  onSliderSelection: (sliderIndex: number) => void;
}

export const CrdProductGalleryMedia: React.FC<Props> = ({
  media,
  mediaSelectedIndex,
  onSliderSelection,
}: Props) => {
  const classes = useStyles();

  const selectMedia = (index: number): Media =>
    media[index] || { source: '', type: MediaType.IMAGE };

  return (
    <>
      <Hidden mdDown>
        <CrdVariationMedia
          isSelected={true}
          className={classes.media}
          media={selectMedia(mediaSelectedIndex)}
        />
      </Hidden>
      <Hidden lgUp>
        <SwipeableViews
          enableMouseEvents
          index={mediaSelectedIndex}
          onChangeIndex={(index: number): void => onSliderSelection(index)}
        >
          {media.map((mediaItem, index) => (
            <CrdVariationMedia
              isSelected={index === mediaSelectedIndex}
              key={index}
              className={classes.media}
              media={mediaItem}
            />
          ))}
        </SwipeableViews>
      </Hidden>
    </>
  );
};
