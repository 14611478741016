import React from 'react';

import { FaqItem } from 'domain/faq/faq.types';

import { CrdExpansionPanelItem } from '../components/panel/expansion-panel-item.component';

interface Props {
  className: string;
  faqs: FaqItem[];
  expandedItemIndex?: number;
  onItemClick: (itemIndex: number) => void;
}

export const CrdExpansionPanelList: React.FC<Props> = ({
  className,
  faqs,
  expandedItemIndex,
  onItemClick,
}: Props) => (
  <div className={className}>
    {faqs.map((faqItem: FaqItem, index: number) => (
      <CrdExpansionPanelItem
        key={index}
        id={index}
        expanded={expandedItemIndex === index}
        onClick={onItemClick}
        item={faqItem}
      />
    ))}
  </div>
);
