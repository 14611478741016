import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { tap } from 'rxjs/operators';

import { Hidden, makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import {
  Color,
  Media,
  Product,
  ProductVariation,
} from 'domain/product/product.types';
import { findVariationMediaByColor } from 'domain/product/product.utils';
import { TRACKING_EVENTS } from 'domain/tracking/tracking.constants';
import { CrdProductVariationConfirmation } from 'modules/product/components/product-variation-confirmation.component';
import { useProductCommands } from 'modules/product/product.commands';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';
import { AppRoutes } from 'pages/app.routes';

import { CrdProductGallery } from './product-gallery.container';
import { CrdProductVariationSelector } from './product-variation-selector.container';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      marginBottom: '96px',
      gap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    title: {
      fontSize: '28px',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      lineHeight: '38px',
      marginBottom: '8px',
      marginTop: 0,
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
    subtitle: {
      fontSize: '14px',
      color: theme.palette.text.secondary,
      fontWeight: 'normal',
      lineHeight: '21px',
      marginTop: '8px',
      marginBottom: '32px',
      [theme.breakpoints.down('xs')]: {
        textAlign: 'center',
      },
    },
  }),
);

interface Props {
  product: Product;
}

export const CrdProductConfigurator: React.FC<Props> = ({ product }: Props) => {
  const { trackEvent } = useTracking();
  const [selectedVariation, setSelectedVariation] = useState<
    ProductVariation
  >();

  const [currentGalleryMedia, setCurrentGalleryMedia] = useState<Array<Media>>(
    product.defaultVariation.media,
  );

  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { selectProduct } = useProductCommands();

  const onConfirmation = (): void => {
    product &&
      selectedVariation &&
      selectProduct({ product, variation: selectedVariation })
        .pipe(
          tap(() => {
            const translatedColor = t(selectedVariation.color.nameLiteral);
            trackEvent({
              event: TRACKING_EVENTS.PRODUCT_PAGE.Selection,
              label: `[${product.displayName}, ${selectedVariation.capacity}, ${translatedColor}]`,
            });
            history.push(AppRoutes.SummaryPage);
          }),
        )
        .subscribe();
  };

  const changeColorHandler = (color: Color): void => {
    const media = findVariationMediaByColor(product.variations, color);
    const translatedColor = t(color.nameLiteral);
    setCurrentGalleryMedia(media);

    trackEvent({
      event: TRACKING_EVENTS.PRODUCT_PAGE.ColorSelection,
      label: `[${product.displayName}, ${translatedColor}]`,
    });
  };

  const changeVariationHandler = (
    variation: ProductVariation | undefined,
  ): void => {
    setSelectedVariation(variation);
    if (variation) {
      trackEvent({
        event: TRACKING_EVENTS.PRODUCT_PAGE.StorageSelection,
        label: `[${product.displayName}, ${variation.capacity}]`,
      });
    }
  };

  useEffect(() => {
    if (selectedVariation) {
      setCurrentGalleryMedia(selectedVariation.media);
    }
  }, [selectedVariation]);

  return (
    <div className={classes.wrapper}>
      <Hidden lgUp>
        <h2 className={classes.title}>
          {t('PRODUCT_FORM.TITLE', {
            productDisplayName: product.displayName,
          })}
        </h2>
      </Hidden>

      <CrdProductGallery media={currentGalleryMedia} />

      <div>
        <Hidden mdDown>
          <h2 className={classes.title}>
            {t('PRODUCT_FORM.TITLE', {
              productDisplayName: product.displayName,
            })}
          </h2>
        </Hidden>

        <CrdProductVariationSelector
          variations={product.variations}
          onChangeColor={changeColorHandler}
          onVariationSelection={changeVariationHandler}
        />
        <CrdProductVariationConfirmation
          disabled={!selectedVariation}
          onConfirmationClick={onConfirmation}
        />
      </div>
    </div>
  );
};
