export const MARKETPLACE_COLORS = {
  main: {
    primary: '#11BB77',
  },
  evaluating: {
    E_01: '#23DB9B',
    E_02: '#9FEDD9',
  },
  neutral: {
    N_00: '#161919',
    N_01: '#252A2A',
    N_02: '#343D3D',
    N_03: '#445151',
    N_04: '#556666',
    N_05: '#667676',
    N_06: '#788686',
    N_07: '#8A9696',
    N_08: '#9DA7A7',
    N_09: '#B0B8B8',
    N_10: '#C3C9C9',
    N_11: '#D7DBDB',
    N_12: '#EBEDED',
    N_17: '#F2F3F3',
    N_18: '#FFFFFF',
  },
  feedback: {
    INFO_01: '#5CAFD2',
    INFO_02: '#92CAE1',
    INFO_03: '#C9E4F0',
    INFO_04: '#EFF7FB',
    ERROR_01: '#DE5656',
    ERROR_02: '#E98E8E',
    ERROR_03: '#F4C7C7',
    ERROR_04: '#FCEFEF',
    WARNING_01: '#CFB055',
    WARNING_02: '#F3C68B',
    WARNING_03: '#F9E2C5',
    WARNING_04: '#FEF7EE',
    SUCCESS_01: '#97CD61',
    SUCCESS_02: '#BADE96',
    SUCCESS_03: '#DCEECA',
    SUCCESS_04: '#F5FAF0',
  },
};
