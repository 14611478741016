import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CatalogItem } from 'domain/catalog/catalog.types';
import { useCatalogCommands } from 'modules/catalog/catalog.commands';
import { CrdCatalogItemPlaceholderComponent } from 'modules/catalog/components/catalog-item/catalog-item-placeholder.component';
import { CatalogItemComponent } from 'modules/catalog/components/catalog-item/catalog-item.component';
import { AppState } from 'redux/store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.layout.columnGap,
      [theme.breakpoints.only('xs')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
);

export const CatalogItemsContainer: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { getCatalog } = useCatalogCommands();

  const catalogItems = useSelector<AppState, CatalogItem[]>(
    state => state.catalog.items,
  );

  const openProduct = useCallback(
    (productId: string): void => {
      history.push(`/apple/${productId}`);
    },
    [history],
  );

  useEffect(() => {
    getCatalog().subscribe();
  }, [getCatalog]);

  return (
    <div className={classes.root} data-testid="catalog-items">
      {catalogItems.length
        ? catalogItems.map(catalogItem => (
            <CatalogItemComponent
              key={catalogItem.id}
              item={catalogItem}
              onClick={openProduct}
            />
          ))
        : Array.from(Array(4)).map((_value, index) => (
            <CrdCatalogItemPlaceholderComponent key={index} />
          ))}
    </div>
  );
};
