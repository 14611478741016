import { MocksService } from './mocks.service';

export class MocksProvider {
  private static instance: MocksService;

  private constructor() {}

  static getInstance(): MocksService {
    if (!MocksProvider.instance) {
      MocksProvider.instance = new MocksService();
    }
    return MocksProvider.instance;
  }
}
