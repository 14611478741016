import { createMuiTheme } from '@material-ui/core/styles';
import { Overrides } from '@material-ui/core/styles/overrides';
import { Shadows } from '@material-ui/core/styles/shadows';

import { MARKETPLACE_COLORS } from './marketplace.colors';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    layout: { columnGap: string; rowGap: string };
    colors: typeof MARKETPLACE_COLORS;
    disabled: { opacity: number };

    shadows: Shadows;
    overrides?: Overrides;
  }

  interface ThemeOptions {
    layout: { columnGap: string; rowGap: string };
    colors: typeof MARKETPLACE_COLORS;
    disabled: { opacity: number };
    overrides?: Overrides;
  }
}

const defaultTheme = createMuiTheme();
const { breakpoints } = defaultTheme;

export const marketplaceTheme = createMuiTheme({
  colors: MARKETPLACE_COLORS,
  layout: {
    columnGap: '30px',
    rowGap: '24px',
  },
  disabled: {
    opacity: 0.3,
  },
  palette: {
    primary: {
      main: MARKETPLACE_COLORS.main.primary,
      contrastText: MARKETPLACE_COLORS.neutral.N_18,
    },
    secondary: {
      main: MARKETPLACE_COLORS.neutral.N_04,
    },
    text: {
      primary: MARKETPLACE_COLORS.neutral.N_02,
      secondary: MARKETPLACE_COLORS.neutral.N_07,
    },
    background: {
      default: '#FFF',
    },
    error: {
      main: MARKETPLACE_COLORS.feedback.ERROR_01,
    },
  },
  // Disabling shadows on all elements!
  shadows: Array(25).fill('none') as Shadows,
  typography: {
    fontFamily: ['"Nunito"', 'Nunito Sans', 'sans-serif'].join(','),
    allVariants: {
      color: MARKETPLACE_COLORS.neutral.N_02,
      fontWeight: 'bold',
    },
    h1: {
      fontSize: '48px',
      lineHeight: '72px',
    },
    h2: {
      fontSize: '32px',
      lineHeight: '48px',
      [breakpoints.down('md')]: {
        fontSize: '26px',
        lineHeight: '34px',
        textAlign: 'center',
      },
    },
    h3: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    h4: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    h5: {
      fontSize: '14px',
      lineHeight: '24px',
    },
    h6: {
      fontSize: '12px',
      lineHeight: '18px',
    },
    body1: {
      fontSize: '16px',
      fontStyle: 'light',
    },
    body2: {
      fontSize: '14px',
      fontStyle: 'light',
    },
    button: {
      fontSize: '16px',
    },
  },
  overrides: {
    // TODO: We have explored this option to override styles but
    // for some reason it does not work. We will keep investigating
    // // this in more depth next week. Sorry! 🙏
    // MuiContainer: {
    //   maxWidthLg: {
    //     maxWidth: '1140px',
    //   },
    // },
    MuiCssBaseline: {
      '@global': {
        '.MuiContainer-maxWidthLg': {
          maxWidth: '1140px',
        },
      },
    },
  },
});
