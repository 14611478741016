import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core';
import { createStyles } from '@material-ui/styles';

import { CrdButton } from 'modules/shared/components/button/button.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    buttonWrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr 1fr',
      margin: '24px 0px',
      [theme.breakpoints.down('md')]: {
        margin: '32px 0px',
      },
    },
    button: {
      height: '40px',
      gridColumnStart: '3',
      color: theme.colors.neutral.N_18,
      fontSize: '16px',
      lineHeight: '24px',
      padding: '0',
      [theme.breakpoints.down('md')]: {
        lineHeight: '22px',
      },
      [theme.breakpoints.only('xs')]: {
        gridColumn: '1 / span 3',
        lineHeight: '24px',
      },
    },
  }),
);

interface Props {
  disabled: boolean;
  onConfirmationClick: () => void;
}
export const CrdProductVariationConfirmation: React.FC<Props> = ({
  disabled,
  onConfirmationClick,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={clsx(disabled && classes.disabled, classes.buttonWrapper)}>
      <CrdButton
        color="primary"
        className={classes.button}
        data-testid="add-to-cart"
        onClick={onConfirmationClick}
      >
        {t('PRODUCT_VARIATION_CONFIRMATION.ADD_TO_CART')}
      </CrdButton>
    </div>
  );
};
