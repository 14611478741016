import { TrackingEventPage } from './tracking.types';

export enum TrackingCategory {
  CATALOG = 'catalog',
  HEADER = 'header',
  HOME = 'home',
  PRODUCT_PAGE = 'productpage',
  SUMMARY = 'summary',
}

export enum TrackingAction {
  BALLOON_PAYMENT_METHOD_SELECTION = 'BalloonPayRemainingAmount',
  BALLOON_RETURN_PAYMENT_METHOD_SELECTION = 'BalloonReturnProduct',
  CATALOG_PAGE_LOAD = 'LoadCatalog',
  CATALOG_PRODUCT_CLICK = 'ViewProduct',
  FAQ_CLICK = 'ajudatab',
  FULL_PAYMENT_METHOD_SELECTION = 'NoBalloon',
  HERO_BUY_BUTTON_CLICK = 'BeginPurchase',
  HERO_HOW_IT_WORKS_BUTTON_CLICK = 'LearnMore',
  HOW_IT_WORKS_CLICK = 'comofuncionatab',
  HOME_PAGE_LOAD = 'LoadHomepage',
  PRODUCT_COLOR_SELECTION = 'SelectColor',
  PRODUCTS_HEADER_CLICK = 'produtostab',
  PRODUCT_PAGE_LOAD = 'LoadProductPage',
  PRODUCT_SELECTION = 'AddToCart',
  PRODUCT_STORAGE_SELECTION = 'SelectCapacity',
  SIDE_MENU_VISUALIZATION = 'MainMenu',
  SUMMARY_CONFIRMATION_CLICK = 'ApprovePreOrder',
  SUMMARY_PAGE_LOAD = 'LoadSummary',
}

export const HEADER: TrackingEventPage = {
  SideMenuVisualization: {
    category: TrackingCategory.HEADER,
    action: TrackingAction.SIDE_MENU_VISUALIZATION,
  },
  Products: {
    category: TrackingCategory.HEADER,
    action: TrackingAction.PRODUCTS_HEADER_CLICK,
  },
  HowItWorks: {
    category: TrackingCategory.HEADER,
    action: TrackingAction.HOW_IT_WORKS_CLICK,
  },
  FAQ: {
    category: TrackingCategory.HEADER,
    action: TrackingAction.FAQ_CLICK,
  },
};

export const HOME_PAGE: TrackingEventPage = {
  PageLoad: {
    category: TrackingCategory.HOME,
    action: TrackingAction.HOME_PAGE_LOAD,
  },
  SideMenuVisualization: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.SIDE_MENU_VISUALIZATION,
  },
};

export const CATALOG_PAGE: TrackingEventPage = {
  PageLoad: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.CATALOG_PAGE_LOAD,
  },
  SideMenuVisualization: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.SIDE_MENU_VISUALIZATION,
  },
  HeroButtonBuyClick: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.HERO_BUY_BUTTON_CLICK,
  },
  HeroButtonHowItWorksClick: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.HERO_HOW_IT_WORKS_BUTTON_CLICK,
  },
  ProductClick: {
    category: TrackingCategory.CATALOG,
    action: TrackingAction.CATALOG_PRODUCT_CLICK,
  },
};

export const PRODUCT_PAGE: TrackingEventPage = {
  PageLoad: {
    category: TrackingCategory.PRODUCT_PAGE,
    action: TrackingAction.PRODUCT_PAGE_LOAD,
  },
  SideMenuVisualization: {
    category: TrackingCategory.PRODUCT_PAGE,
    action: TrackingAction.SIDE_MENU_VISUALIZATION,
  },
  ColorSelection: {
    category: TrackingCategory.PRODUCT_PAGE,
    action: TrackingAction.PRODUCT_COLOR_SELECTION,
  },
  StorageSelection: {
    category: TrackingCategory.PRODUCT_PAGE,
    action: TrackingAction.PRODUCT_STORAGE_SELECTION,
  },
  Selection: {
    category: TrackingCategory.PRODUCT_PAGE,
    action: TrackingAction.PRODUCT_SELECTION,
  },
};

export const SUMMARY_PAGE: TrackingEventPage = {
  PageLoad: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.SUMMARY_PAGE_LOAD,
  },
  SideMenuVisualization: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.SIDE_MENU_VISUALIZATION,
  },
  FullPaymentMethodSelection: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.FULL_PAYMENT_METHOD_SELECTION,
  },
  BalloonReturnPaymentMethodSelection: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.BALLOON_RETURN_PAYMENT_METHOD_SELECTION,
  },
  BalloonPaymentMethodSelection: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.BALLOON_PAYMENT_METHOD_SELECTION,
  },
  ConfirmationClick: {
    category: TrackingCategory.SUMMARY,
    action: TrackingAction.SUMMARY_CONFIRMATION_CLICK,
  },
};

export const EVENT_PATH_MAPPING: { [name: string]: string } = {
  landing: 'CATALOG_PAGE',
  catalog: 'PRODUCT_PAGE',
  summary: 'SUMMARY_PAGE',
  checkout: 'CHECKOUT_PAGE',
  success: 'SUCCESS_PAGE',
};

export const TRACKING_EVENTS: { [name: string]: any } = {
  HOME_PAGE,
  CATALOG_PAGE,
  PRODUCT_PAGE,
  SUMMARY_PAGE,
};

export enum TRACKING_TITLES {
  CATALOG_PAGE = 'Catalog page',
  PRODUCT_PAGE = 'Product page',
  SUMMARY_PAGE = 'Pre Order Summary',
}
