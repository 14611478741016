import React, { ReactElement } from 'react';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import WarningIcon from '@material-ui/icons/Warning';

import { MARKETPLACE_COLORS } from 'styles/marketplace.colors';

import { NotificationGroup, NotificationType } from './notification.types';

export const NOTIFICATION_GROUP: NotificationGroup = {
  [NotificationType.ERROR]: {
    renderIcon: function Error(): ReactElement {
      return (
        <ErrorIcon style={{ color: MARKETPLACE_COLORS.feedback.ERROR_01 }} />
      );
    },
    backgroundColor: MARKETPLACE_COLORS.feedback.ERROR_04,
  },
  [NotificationType.INFO]: {
    renderIcon: function Info(): ReactElement {
      return (
        <NotificationImportantIcon
          style={{ color: MARKETPLACE_COLORS.feedback.INFO_01 }}
        />
      );
    },
    backgroundColor: MARKETPLACE_COLORS.feedback.INFO_04,
  },
  [NotificationType.SUCCESS]: {
    renderIcon: function Success(): ReactElement {
      return (
        <CheckCircleIcon
          style={{ color: MARKETPLACE_COLORS.feedback.SUCCESS_01 }}
        />
      );
    },
    backgroundColor: MARKETPLACE_COLORS.feedback.SUCCESS_04,
  },
  [NotificationType.WARNING]: {
    renderIcon: function Warning(): ReactElement {
      return (
        <WarningIcon
          style={{ color: MARKETPLACE_COLORS.feedback.WARNING_01 }}
        />
      );
    },
    backgroundColor: MARKETPLACE_COLORS.feedback.WARNING_04,
  },
};
