import React from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FaqContainer } from 'modules/faq/containers/faq.container';

import { CrdMarketplacePage } from '../misc/marketplace.page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '72px',
      color: theme.palette.text.primary,
      marginBottom: '48px',
      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '60px',
        marginBottom: '40px',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '36px',
        marginBottom: '32px',
      },
    },
  }),
);

export const FaqPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <CrdMarketplacePage>
      <Typography className={classes.title} variant="h2" component="h1">
        {t('FAQ.HEADLINE')}
      </Typography>
      <FaqContainer />
    </CrdMarketplacePage>
  );
};
