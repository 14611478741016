import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CatalogItem } from 'domain/catalog/catalog.types';
import { TRACKING_EVENTS } from 'domain/tracking/tracking.constants';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      cursor: 'pointer',
    },
    imageWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
    },
    catalogItemImage: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      objectFit: 'scale-down',
      top: 0,
    },
    catalogItemText: {
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      margin: 0,
      textAlign: 'center',
    },
    catalogItemName: {
      fontSize: 28,
      lineHeight: '38px',
    },
    catalogItemPrice: {
      fontSize: 21,
      lineHeight: '31px',
    },
  }),
);

export interface CatalogItemProps {
  item: CatalogItem;
  onClick?: (catalogItemId: string) => void;
}

export const CatalogItemComponent: React.FC<CatalogItemProps> = ({
  item,
  onClick,
}: CatalogItemProps) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const selectCatalogItem = useCallback(() => {
    onClick && onClick(item.id);
    trackEvent({
      event: TRACKING_EVENTS.CATALOG_PAGE.ProductClick,
      label: `[${item.displayName}, ${item.minimumMonthlyPrice}]`,
    });
  }, [onClick, item, trackEvent]);
  return (
    <div
      className={classes.root}
      onClick={selectCatalogItem}
      onKeyPress={selectCatalogItem}
      role="link"
      tabIndex={0}
      data-testid="product-link"
    >
      <div className={classes.imageWrapper}>
        <img
          src={item.image}
          alt={item.displayName}
          className={classes.catalogItemImage}
        />
      </div>
      <h3 className={clsx(classes.catalogItemName, classes.catalogItemText)}>
        {item.displayName}
      </h3>
      <h4 className={clsx(classes.catalogItemPrice, classes.catalogItemText)}>
        {t('CATALOG_ITEM.INSTALLMENT_FROM', {
          installment: item.minimumMonthlyPrice,
        })}
      </h4>
    </div>
  );
};
