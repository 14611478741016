import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { LinkItem } from 'domain/navigation/navigation.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { textDecoration: 'none' },
    linkText: {
      height: '22px',
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '16px',
      lineHeight: '22px',
      textAlign: 'center',
      textTransform: 'uppercase',
      color: theme.colors.neutral.N_07,
      [theme.breakpoints.down('md')]: {
        fontSize: '14px',
        lineHeight: '21px',
      },
    },
  }),
);

export interface NavbarLinkProps {
  className?: string;
  linkItem: LinkItem;
  onClick: (trackingSection: string) => void;
}

export const NavbarLinkComponent: React.FC<NavbarLinkProps> = ({
  className,
  linkItem,
  onClick,
}: NavbarLinkProps) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Link
      className={clsx(classes.root, className)}
      to={linkItem.linksTo}
      data-testid="marketplace-navbar-link"
      onClick={(): void => onClick(linkItem.options?.trackingSection || '')}
    >
      <span className={classes.linkText}>{t(linkItem.textLiteral)}</span>
    </Link>
  );
};
