import { Fetch } from './fetch.interface';
import { HttpFetchService } from './http-fetch.service';
import { MockedFetchService } from './mocked-fetch.service';

export class FetchProvider {
  private static instance: Fetch;

  private constructor() {}

  static getInstance(): Fetch {
    if (!FetchProvider.instance) {
      const selectedFetchMode = process.env.REACT_APP_FETCH_MODE;
      switch (selectedFetchMode.toUpperCase()) {
        case 'BACKEND': {
          FetchProvider.instance = new HttpFetchService();
          break;
        }
        case 'MOCKED': {
          FetchProvider.instance = new MockedFetchService();
          break;
        }
        default: {
          throw new Error(`Unknown http service mode: ${selectedFetchMode}`);
        }
      }
    }
    return FetchProvider.instance;
  }
}
