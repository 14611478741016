import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';

import {
  ADD_SHOPPING_CART_ITEM,
  REMOVE_SHOPPING_CART_ITEM,
  ShoppingCartActionTypes,
} from './shoppingCart.actions';

export interface ShoppingCartState {
  items: ShoppingCartItem[];
}

const initialState: ShoppingCartState = {
  items: [],
};

export function shoppingCartReducer(
  state = initialState,
  action: ShoppingCartActionTypes,
): ShoppingCartState {
  switch (action.type) {
    case ADD_SHOPPING_CART_ITEM:
      return {
        ...state,
        items: [action.payload],
      };
    case REMOVE_SHOPPING_CART_ITEM:
      return {
        ...state,
        items: [],
      };
    default:
      return state;
  }
}
