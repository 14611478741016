import { PaymentMethodType } from 'domain/payment-method/payment-method.types';

import {
  ADD_PAYMENT_METHOD,
  PaymentMethodActionTypes,
} from './paymentMethod.actions';

export interface PaymentMethodState {
  type: PaymentMethodType;
}

const initialState: PaymentMethodState = {
  type: PaymentMethodType.FULL,
};

export function paymentMethodReducer(
  state = initialState,
  action: PaymentMethodActionTypes,
): PaymentMethodState {
  switch (action.type) {
    case ADD_PAYMENT_METHOD:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
}
