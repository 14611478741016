import { HowItWorksItem } from './how-it-works.types';

export const HOW_IT_WORKS_ITEMS: HowItWorksItem[] = [
  {
    number: 1,
    text: {
      titleLiteral: 'HOW_IT_WORKS.ITEMS.1.TITLE',
      descriptionLiteral: 'HOW_IT_WORKS.ITEMS.1.DESCRIPTION',
    },
  },
  {
    number: 2,
    text: {
      titleLiteral: 'HOW_IT_WORKS.ITEMS.2.TITLE',
      descriptionLiteral: 'HOW_IT_WORKS.ITEMS.2.DESCRIPTION',
    },
  },
  {
    number: 3,
    text: {
      titleLiteral: 'HOW_IT_WORKS.ITEMS.3.TITLE',
      descriptionLiteral: 'HOW_IT_WORKS.ITEMS.3.DESCRIPTION',
    },
  },
  {
    number: 4,
    hideNumber: true,
    text: {
      titleLiteral: 'HOW_IT_WORKS.ITEMS.4.TITLE',
      descriptionLiteral: 'HOW_IT_WORKS.ITEMS.4.DESCRIPTION',
    },
  },
  {
    number: 5,
    hideNumber: true,
    text: {
      titleLiteral: 'HOW_IT_WORKS.ITEMS.5.TITLE',
      descriptionLiteral: 'HOW_IT_WORKS.ITEMS.5.DESCRIPTION',
    },
  },
];
