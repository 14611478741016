import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { Product } from 'domain/product/product.types';
import { FetchProvider } from 'services/http/fetch.provider';

export const getProduct = (productId: string): Observable<Product> => {
  return FetchProvider.getInstance()
    .fetch(`/assets/data/products/${productId}.json`)
    .pipe(flatMap(res => res.json()));
};
