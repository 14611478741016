import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { AppRoutes } from 'pages/app.routes';

import marketplaceLogo from './marketplace-logo.png';

const useStyles = makeStyles(() =>
  createStyles({
    root: {},
    logoImage: {
      verticalAlign: 'middle',
    },
  }),
);

export interface MarketplaceLogoProps {
  className?: string;
  width: number;
}

export const MarketplaceLogoComponent: React.FC<MarketplaceLogoProps> = ({
  width,
  className,
}: MarketplaceLogoProps) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Link
      to={AppRoutes.CatalogPage}
      className={clsx(className, classes.root)}
      data-testid="marketplace-logo-link"
    >
      <img
        src={marketplaceLogo}
        width={width}
        className={classes.logoImage}
        data-testid="marketplace-logo-image"
        alt={t('MARKETPLACE_LOGO.MARKETPLACE_LOGO_ALT')}
      />
    </Link>
  );
};
