import i18next from 'i18next';

import { FAQ_SECTIONS } from './faq.constants';
import { FaqItem, FaqSection } from './faq.types';

export const getSections = (): FaqSection[] => FAQ_SECTIONS;

export const getSection = (uri: string | null | undefined): FaqSection => {
  const sections = getSections();
  const defaultSection = sections[0];
  if (!uri) {
    return defaultSection;
  }
  return (
    sections.find(section => i18next.t(section.uriLiteral) === uri) ||
    defaultSection
  );
};

export const findFaqIndexByUri = (
  faqs: FaqItem[],
  uri: string | null | undefined,
): number | undefined => {
  return uri
    ? faqs.findIndex(faq => faq.uriLiteral && i18next.t(faq.uriLiteral) === uri)
    : undefined;
};
