import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { CATALOG_CONTAINER_ID } from 'domain/catalog/catalog.constants';
import {
  TRACKING_TITLES,
  TrackingAction,
  TrackingCategory,
} from 'domain/tracking/tracking.constants';
import { CatalogHeroContainer } from 'modules/catalog/containers/catalog-hero/catalog-hero.container';
import { CatalogItemsContainer } from 'modules/catalog/containers/catalog-items/catalog-items.container';
import { useTracking } from 'modules/shared/hooks/use-tracking.hook';
import { AppRoutes } from 'pages/app.routes';

import { CrdMarketplacePage } from '../misc/marketplace.page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    headline: {
      textAlign: 'center',
    },
    disclaimer: {
      fontSize: '12px',
      lineHeight: '38px',
      textAlign: 'center',
      marginBottom: '64px',
    },
    nonIphoneLink: {
      textAlign: 'center',
      marginTop: '64px',
      color: theme.colors.main.primary,
    },
  }),
);

export const CatalogPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { trackPageLoad } = useTracking();

  useEffect(() => {
    trackPageLoad({
      page: {
        category: TrackingCategory.CATALOG,
        action: TrackingAction.CATALOG_PAGE_LOAD,
        title: TRACKING_TITLES.CATALOG_PAGE,
        path: AppRoutes.CatalogPage,
      },
    });
  }, [trackPageLoad]);

  return (
    <CrdMarketplacePage
      heroElement={<CatalogHeroContainer />}
      containerId={CATALOG_CONTAINER_ID}
    >
      <Typography className={classes.headline} variant="h2" component="h2">
        {t('CATALOG_PAGE.HEADLINE')}
      </Typography>

      <p className={classes.disclaimer}>{t('CATALOG_PAGE.DISCLAIMER')}</p>

      <CatalogItemsContainer />

      <a
        className={classes.nonIphoneLink}
        href={'https://form.jotform.co/202754612025851'}
        target="_blank"
        rel="noopener noreferrer"
      >
        <h3>{t('CATALOG_PAGE.NON_IPHONE_LINK_TEXT')}</h3>
      </a>
    </CrdMarketplacePage>
  );
};
