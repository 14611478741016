import { createContext, useContext } from 'react';

import { emptyFunction } from 'domain/shared/shared.utils';
import { TrackingContextProps } from 'domain/tracking/tracking.types';

const TrackingContext = createContext<TrackingContextProps>({
  trackEvent: emptyFunction,
  trackPageLoad: emptyFunction,
});

export const TrackingProvider = TrackingContext.Provider;

export const useTracking = (): TrackingContextProps =>
  useContext(TrackingContext);
