import { Observable } from 'rxjs';
import { flatMap } from 'rxjs/operators';

import { CatalogItem } from 'domain/catalog/catalog.types';
import { FetchProvider } from 'services/http/fetch.provider';

export const getCatalog = (): Observable<CatalogItem[]> => {
  return FetchProvider.getInstance()
    .fetch('/assets/data/catalog.json')
    .pipe(flatMap(res => res.json()));
};
