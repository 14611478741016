import React, { useEffect, useState } from 'react';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Media } from 'domain/product/product.types';
import { CrdProductGalleryMedia } from 'modules/product/components/product-gallery-media.component';
import { CrdProductGallerySlider } from 'modules/product/components/product-gallery-slider.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: '1fr 5fr',
      gridColumnGap: theme.layout.columnGap,
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
  }),
);

interface Props {
  media: Media[];
}

export const CrdProductGallery: React.FC<Props> = ({ media }: Props) => {
  const classes = useStyles();
  const [currentGalleryMedia, setCurrentGalleryMedia] = useState<number>(0);

  useEffect(() => {
    setCurrentGalleryMedia(0);
  }, [media]);

  return (
    <div>
      <div className={classes.wrapper}>
        <CrdProductGallerySlider
          thumbnails={media.map(x => ({ source: x.thumbnail, type: x.type }))}
          thumbnailSelectedIndex={currentGalleryMedia}
          onSliderSelection={setCurrentGalleryMedia}
        />

        <CrdProductGalleryMedia
          media={media}
          mediaSelectedIndex={currentGalleryMedia}
          onSliderSelection={setCurrentGalleryMedia}
        />
      </div>
    </div>
  );
};
