import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button, ButtonGroup, createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { FaqSection } from 'domain/faq/faq.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 'normal',
      border: `1px solid ${theme.colors.neutral.N_04}`,
      textTransform: 'none',
      padding: '8px 48px',
    },
    selected: {
      backgroundColor: theme.palette.secondary.main,
      color: theme.colors.neutral.N_18,
      '&:hover': {
        backgroundColor: theme.palette.secondary.main,
      },
      '&:active': {
        backgroundColor: theme.palette.secondary.main,
      },
    },
  }),
);

interface Props {
  className: string;
  currentSelected: FaqSection;
  sections: FaqSection[];
  onSelect: (id: FaqSection) => void;
}
export const CrdFaqTab: React.FC<Props> = ({
  className,
  currentSelected,
  sections,
  onSelect,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <ButtonGroup classes={{ root: className }}>
        {sections.map(section => (
          <Button
            key={section.uriLiteral}
            onClick={(): void => onSelect(section)}
            role="link"
            classes={{
              root: clsx(
                classes.button,
                currentSelected.uriLiteral === section.uriLiteral &&
                  classes.selected,
              ),
            }}
          >
            {t(section.titleLiteral)}
          </Button>
        ))}
      </ButtonGroup>
    </>
  );
};
