import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { getCatalog as getCatalogApi } from 'api/catalog.api';
import { setCatalogItems } from 'redux/catalog/catalog.actions';
import { store } from 'redux/store';

interface CatalogCommands {
  getCatalog: () => Observable<undefined>;
}

const getCatalog = (): Observable<undefined> =>
  getCatalogApi().pipe(
    tap(catalogItems => {
      store.dispatch(setCatalogItems(catalogItems));
    }),
    map(() => undefined),
  );

export const useCatalogCommands = (): CatalogCommands => {
  return { getCatalog };
};
