import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  createStyles,
  FormControl,
  makeStyles,
  Select,
  Theme,
} from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';

import { FaqSection } from 'domain/faq/faq.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    select: {
      fontSize: '14px',
      lineHeight: '21px',
      padding: '0 8px',
      minHeight: '40px',
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      '&:focus': {
        backgroundColor: theme.colors.neutral.N_18,
      },
    },
    placeholder: {
      fontSize: '14px',
      lineHeight: '21px',
      fontWeight: 'normal',
      color: theme.colors.neutral.N_08,
    },
  }),
);

interface Props {
  className: string;
  currentSelected: FaqSection;
  sections: FaqSection[];
  onSelect: (section: FaqSection) => void;
}
export const CrdFaqDropDown: React.FC<Props> = ({
  className,
  currentSelected,
  sections,
  onSelect,
}: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl variant="outlined" classes={{ root: className }}>
      <Select
        native
        startAdornment={
          <InputAdornment position="end">
            <div className={classes.placeholder}>
              {t('FAQ.SELECTOR_PLACEHOLDER')}
            </div>
          </InputAdornment>
        }
        classes={{ root: classes.select }}
        value={sections.findIndex(
          section => currentSelected.uriLiteral === section.uriLiteral,
        )}
        onChange={(event): void =>
          onSelect(sections[parseInt(event.target.value as string)])
        }
      >
        {sections.map((section, index) => (
          <option key={index} value={index}>
            {t(section.titleLiteral)}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};
