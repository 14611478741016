import { CatalogItem } from 'domain/catalog/catalog.types';

export const SET_CATALOG_ITEMS = 'SET_CATALOG_ITEMS';

interface SetCatalogItemsAction {
  type: typeof SET_CATALOG_ITEMS;
  payload: CatalogItem[];
}

export type CatalogActionTypes = SetCatalogItemsAction;

export function setCatalogItems(
  catalogItems: CatalogItem[],
): CatalogActionTypes {
  return {
    type: SET_CATALOG_ITEMS,
    payload: catalogItems,
  };
}
