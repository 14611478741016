import clsx from 'clsx';
import React, { useCallback } from 'react';

import { createStyles, Hidden, Theme } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import { makeStyles } from '@material-ui/styles';

import { MediaType } from 'domain/product/product.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      [theme.breakpoints.down('md')]: {
        display: 'flex',
        gridRowStart: '2',
        justifyContent: 'center',
      },
      [theme.breakpoints.down('xs')]: {
        marginTop: '16px',
      },
    },
    thumbnailContainer: {
      border: `1px solid ${theme.colors.neutral.N_12}`,
      marginBottom: '10px',
      maxWidth: '90px',
      cursor: 'pointer',
      position: 'relative',
      [theme.breakpoints.down('md')]: {
        margin: '0 5px',
      },
    },
    playButtonWrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      opacity: 0.5,
      backgroundColor: theme.colors.neutral.N_00,
    },
    playButton: {
      position: 'relative',
      maxWidth: '100%',
      minHeight: '85px',
      color: '#FFFFFF',
    },
    thumbnail: {
      display: 'block',
      maxWidth: '100%',
      minHeight: '85px',
      [theme.breakpoints.down('md')]: {
        maxHeight: '90px',
      },
    },
    thumbnailSelected: {
      borderColor: theme.colors.neutral.N_09,
    },
    icon: {
      color: theme.colors.neutral.N_10,
      fontSize: '16px',
      margin: '0 12px',
    },
    iconSelected: {
      color: theme.colors.neutral.N_01,
    },
  }),
);

interface Props {
  thumbnails: Array<{ source: string; type: MediaType }>;
  thumbnailSelectedIndex: number;
  onSliderSelection: (sliderIndex: number) => void;
}

export const CrdProductGallerySlider: React.FC<Props> = ({
  thumbnails,
  thumbnailSelectedIndex,
  onSliderSelection,
}: Props) => {
  const classes = useStyles();
  const selectGalleryImage = useCallback(
    (index: number) => (): void => {
      onSliderSelection(index);
    },
    [onSliderSelection],
  );

  return (
    <div className={classes.wrapper}>
      {thumbnails.map((thumbnail, index) => (
        <React.Fragment key={index}>
          <Hidden xsDown>
            <div
              className={clsx(
                index === thumbnailSelectedIndex && classes.thumbnailSelected,
                classes.thumbnailContainer,
              )}
              onClick={selectGalleryImage(index)}
              onKeyPress={selectGalleryImage(index)}
              role="link"
              tabIndex={0}
            >
              {thumbnail.type === MediaType.VIDEO && (
                <div className={classes.playButtonWrapper}>
                  <PlayCircleOutlineIcon
                    style={{ fontSize: 44 }}
                    className={classes.playButton}
                  ></PlayCircleOutlineIcon>
                </div>
              )}

              <img
                className={classes.thumbnail}
                src={thumbnail.source}
                alt="product"
              />
            </div>
          </Hidden>

          <Hidden smUp>
            <FiberManualRecordIcon
              onClick={selectGalleryImage(index)}
              classes={{
                root: clsx(
                  classes.icon,
                  index === thumbnailSelectedIndex && classes.iconSelected,
                ),
              }}
            />
          </Hidden>
        </React.Fragment>
      ))}
    </div>
  );
};
