import clsx from 'clsx';
import React from 'react';

import { createStyles, makeStyles } from '@material-ui/core';
import Button, { ButtonProps } from '@material-ui/core/Button';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      padding: '13px 16px',
    },
    buttonLabel: {
      fontWeight: 'normal',
      lineHeight: '1.375',
      textTransform: 'none',
    },
    small: {
      fontSize: '12px',
    },
    medium: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    large: {
      fontSize: '20px',
      lineHeight: '1.2',
    },
  }),
);

type ExtendedButtonProps = ButtonProps<'button', CustomProps>;

// If new custom props are needed, they can be added to this empty type.
type CustomProps = {};

export const CrdButton: React.FC<ExtendedButtonProps> = (
  props: ExtendedButtonProps,
) => {
  const classes = useStyles();
  const size = props.size ? props.size : 'medium';

  return (
    <Button
      variant="contained"
      color="primary"
      classes={{
        root: classes.button,
        label: clsx(classes.buttonLabel, classes[size]),
      }}
      {...props}
    />
  );
};
