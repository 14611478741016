import { CatalogItem } from 'domain/catalog/catalog.types';

import { CatalogActionTypes, SET_CATALOG_ITEMS } from './catalog.actions';

export interface CatalogState {
  items: CatalogItem[];
}

const initialState: CatalogState = {
  items: [],
};

export function catalogReducer(
  state = initialState,
  action: CatalogActionTypes,
): CatalogState {
  switch (action.type) {
    case SET_CATALOG_ITEMS:
      return {
        ...state,
        items: [...action.payload],
      };
    default:
      return state;
  }
}
