import React from 'react';

import { makeStyles, Theme } from '@material-ui/core';

import { CrdTranslator } from '../translator/translator.component';
import { NOTIFICATION_GROUP } from './notification.constants';
import { NotificationType } from './notification.types';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: '4px',
    padding: '16px',
    display: 'flex',
  },
  iconWrapper: {
    marginRight: '16px',
  },
  textWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
  text: {
    fontFamily: 'Nunito Sans',
    fontWeight: 'normal',
    fontSize: '16px',
    lineHeight: '24px',
    [theme.breakpoints.only('xs')]: {
      fontSize: '14px',
      lineHeight: '21px',
    },
  },
}));

interface Props {
  type: keyof typeof NotificationType;
  textLiteral: string;
  titleLiteral?: string;
}

export const CrdNotification: React.FC<Props> = ({
  type,
  titleLiteral,
  textLiteral,
}: Props) => {
  const classes = useStyles({ type });
  const { renderIcon, backgroundColor } = NOTIFICATION_GROUP[type];

  return (
    <div className={classes.root} style={{ backgroundColor }}>
      <div className={classes.iconWrapper}>{renderIcon()}</div>
      <div className={classes.textWrapper}>
        {!!titleLiteral && (
          <span className={classes.title}>
            <CrdTranslator literal={titleLiteral} />
          </span>
        )}
        <span className={classes.text}>
          <CrdTranslator literal={textLiteral} />
        </span>
      </div>
    </div>
  );
};
