import clsx from 'clsx';
import React, { ReactElement } from 'react';

import { createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { Media, MediaType } from '../../../domain/product/product.types';

const useStyles = makeStyles(() =>
  createStyles({
    mediaWrapper: {
      position: 'relative',
      width: '100%',
      paddingTop: '100%',
    },
    media: {
      width: '100%',
      objectFit: 'scale-down',
      position: 'absolute',
      top: 0,
    },
    image: {
      height: '100%',
    },
    video: {
      height: '63%',
      top: '18.5%',
    },
  }),
);

interface Props {
  className?: string;
  media: Media;
  isSelected: boolean;
}

export const CrdVariationMedia: React.FC<Props> = ({
  className,
  media,
  isSelected,
}: Props) => {
  const classes = useStyles();

  const render = (mediaType: MediaType): ReactElement =>
    ({
      [MediaType.VIDEO]: (
        <div>
          {isSelected && (
            <iframe
              title={media.source}
              src={media.source}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className={clsx(classes.media, classes.video)}
            />
          )}
        </div>
      ),
      [MediaType.IMAGE]: (
        <img
          src={media.source}
          alt={media.source}
          className={clsx(classes.media, classes.image)}
        />
      ),
    }[mediaType]);

  return (
    <div className={clsx(classes.mediaWrapper, className)}>
      {render(media.type)}
    </div>
  );
};
