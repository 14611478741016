import { PaymentMethodType } from 'domain/payment-method/payment-method.types';

export const ADD_PAYMENT_METHOD = 'ADD_PAYMENT_METHOD';

interface AddPaymentMethod {
  type: typeof ADD_PAYMENT_METHOD;
  payload: PaymentMethodType;
}

export type PaymentMethodActionTypes = AddPaymentMethod;

export function addPaymentMethodType(
  paymentMethodType: PaymentMethodType,
): AddPaymentMethod {
  return {
    type: ADD_PAYMENT_METHOD,
    payload: paymentMethodType,
  };
}
