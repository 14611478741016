import { ReactElement } from 'react';

export enum NotificationType {
  ERROR = 'ERROR',
  INFO = 'INFO',
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
}

export interface Notification {
  renderIcon: () => ReactElement;
  backgroundColor: string;
}

export type NotificationGroup = {
  [K in keyof typeof NotificationType]: Notification;
};
