import { MOCKED_USER_ID } from '../constants/purchaseDataConstants';

const mockedUserResponse = { id: MOCKED_USER_ID };

export const identificationDocuments = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedUserResponse)));
  });
};

export const signOrderContract = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedUserResponse)));
  });
};

export const createAddressMock = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(null));
  });
};

export const validateUserCPFMock = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(null));
  });
};

export const createUserMock = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedUserResponse)));
  });
};
