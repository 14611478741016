import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { ProductStorageAvailability } from 'domain/product/product.types';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    disabled: {
      opacity: 0.2,
      pointerEvents: 'none',
    },
    storage: {
      fontSize: '24px',
    },
    installment: {
      lineHeight: '24px',
      fontSize: '16px',
      textAlign: 'center',
      color: theme.palette.text.secondary,
      margin: 0,
    },
    title: {
      fontSize: '21px',
      color: theme.palette.text.primary,
      fontWeight: 'bold',
      lineHeight: '31px',
      marginTop: '32px',
      marginBottom: '8px',
    },
    optionsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 1fr',
      gap: theme.layout.columnGap,
    },
    configuration: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderRadius: '4px',
      borderColor: theme.palette.text.secondary,
      height: '112px',
      paddingTop: '24px',
      paddingBottom: '24px',
      cursor: 'pointer',
      outline: 'none',
    },
    selectedConfiguration: {
      borderColor: theme.colors.main.primary,
    },
  }),
);

interface Props {
  disabled: boolean;
  storages: ProductStorageAvailability[];
  selectedStorage?: string;
  onStorageSelection: (storage: string) => void;
}

export const CrdStorageConfiguration: React.FC<Props> = ({
  disabled,
  storages,
  selectedStorage,
  onStorageSelection,
}: Props) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const selectStorage = useCallback(
    (storage: string) => (): void => {
      onStorageSelection(storage);
    },
    [onStorageSelection],
  );

  return (
    <div className={clsx(disabled && classes.disabled)}>
      <h4 className={classes.title}>
        {t('PRODUCT_FORM.CONFIGURATION.STORAGE.TITLE')}
      </h4>
      <div className={classes.optionsContainer}>
        {storages.map(storage => {
          return (
            <div
              className={clsx(
                classes.configuration,
                !storage.enabled && !disabled && classes.disabled,
                selectedStorage &&
                  selectedStorage === storage.capacity &&
                  classes.selectedConfiguration,
              )}
              data-testid={storage.enabled ? 'storage-box' : 'storage-disabled'}
              key={storage.capacity}
              onClick={selectStorage(storage.capacity)}
              onKeyPress={selectStorage(storage.capacity)}
              role="link"
              tabIndex={0}
            >
              <p className={classes.installment}>
                <span className={classes.storage}>{storage.capacity}</span>
              </p>
              <p className={classes.installment}>
                {t('PRODUCT_FORM.CONFIGURATION.STORAGE.INSTALLMENT', {
                  installment: storage.installment,
                })}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
