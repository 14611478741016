import React from 'react';

import {
  CrdContentLoader,
  PLACEHOLDER_BORDER_RADIUS,
} from 'modules/shared/components/content-loader/content-loader.component';

export const CrdProductDescriptionPlaceholder: React.FC = () => {
  return (
    <>
      <CrdContentLoader height={60}>
        <rect
          rx={PLACEHOLDER_BORDER_RADIUS}
          ry={PLACEHOLDER_BORDER_RADIUS}
          width="40%"
          height="20"
          x="30%"
        />
        <rect
          rx={PLACEHOLDER_BORDER_RADIUS}
          ry={PLACEHOLDER_BORDER_RADIUS}
          width="70%"
          height={20}
          x="15%"
          y="30"
        />
      </CrdContentLoader>
      <CrdContentLoader height={400} animate={false}>
        <rect
          rx={PLACEHOLDER_BORDER_RADIUS}
          ry={PLACEHOLDER_BORDER_RADIUS}
          width={400}
          height={400}
        />
      </CrdContentLoader>
    </>
  );
};
