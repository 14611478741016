import React from 'react';
import { useTranslation } from 'react-i18next';

import { Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flex: '1',
      flexDirection: 'column',
      justifyContent: 'center',
      textAlign: 'center',
    },
    headline: theme.typography.h2,
    description: theme.typography.h4,
    link: {
      color: theme.colors.neutral.N_07,
      textDecoration: 'none',
    },
  }),
);

export const CrdShoppingCartEmpty: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.container}>
      <h2 className={classes.headline}>{t('SHOPPING_CART.EMPTY.HEADLINE')}</h2>
      <p className={classes.description}>
        <a href={process.env.REACT_APP_STORE_PATH} className={classes.link}>
          {t('SHOPPING_CART.EMPTY.DESCRIPTION_LINK')}
        </a>
        {t('SHOPPING_CART.EMPTY.DESCRIPTION_TEXT')}
      </p>
    </div>
  );
};
