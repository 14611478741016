import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';

import { useTheme } from '@material-ui/core';

const ANIMATION_SPEED = 2;
export const PLACEHOLDER_BORDER_RADIUS = 4;

// This is a ñapa. https://calidadysoftware.wordpress.com/2015/07/10/naas-napa-as-a-service/
// The problem is the base href /store in production. For some reason, Safari is not able to render the svg.
// This should be removed when CloudFlare is active and AZION is removed (it introduced the /store problem).
const isSafari =
  navigator.userAgent.search('Safari') >= 0 &&
  navigator.userAgent.search('Chrome') < 0;

export const CrdContentLoader: React.FC<IContentLoaderProps> = (
  props: IContentLoaderProps,
) => {
  const theme = useTheme();
  return isSafari ? (
    <div />
  ) : (
    <ContentLoader
      // There is an issue with Safari and the content loader. For some reason, when the base href is modified, Safari support is broken.
      // Theorically, this change should fix it. However, it does not.
      // We already tried with {window.location.pathname}, '/', {process.env.REACT_APP_STORE_PATH}
      // baseUrl={process.env.REACT_APP_STORE_PATH}
      speed={ANIMATION_SPEED}
      primaryColor={theme.colors.neutral.N_17}
      secondaryColor={theme.colors.neutral.N_12}
      {...props}
    />
  );
};
