import { ShoppingCartItem } from 'domain/shopping-cart/shopping-cart.types';

export const ADD_SHOPPING_CART_ITEM = 'ADD_SHOPPING_CART_ITEM';
export const REMOVE_SHOPPING_CART_ITEM = 'REMOVE_SHOPPING_CART_ITEM';

interface AddShoppingCartItemAction {
  type: typeof ADD_SHOPPING_CART_ITEM;
  payload: ShoppingCartItem;
}
interface RemoveShoppingCartItemAction {
  type: typeof REMOVE_SHOPPING_CART_ITEM;
}

export type ShoppingCartActionTypes =
  | AddShoppingCartItemAction
  | RemoveShoppingCartItemAction;

export function addShoppingCartItem(
  shoppingCartItem: ShoppingCartItem,
): ShoppingCartActionTypes {
  return {
    type: ADD_SHOPPING_CART_ITEM,
    payload: shoppingCartItem,
  };
}

export function removeShoppingCartItem(): ShoppingCartActionTypes {
  return {
    type: REMOVE_SHOPPING_CART_ITEM,
  };
}
