import TagManager from 'react-gtm-module';

import {
  TrackingContextProps,
  TrackingEvent,
  TrackingPage,
} from './tracking.types';

const sendTrackingEvent = (eventData: object): void => {
  TagManager.dataLayer({
    dataLayer: eventData,
  });
};

const trackEvent = ({
  event,
  label,
}: {
  event: TrackingEvent;
  label?: string;
}): void => {
  sendTrackingEvent({
    event: 'gaCreditasEvent',
    category: event.category,
    action: event.action,
    label,
  });
};

const trackPageLoad = ({
  page,
  label,
}: {
  page: TrackingPage;
  label?: string;
}): void => {
  sendTrackingEvent({
    event: 'creditas.pageview',
    title: page.title,
    path: page.path,
    label,
  });
};

export const tracking: TrackingContextProps = {
  trackEvent,
  trackPageLoad,
};
