import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Theme } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/styles';

import {
  FOOTER_LINKS,
  FOOTER_SECONDARY_LINKS,
} from 'domain/navigation/navigation.constants';

import { CrdFooterLinks } from './footer-links.component';
import { CrdFooterText } from './footer-text.component';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: '#FAFAFA',
    },
    container: {
      height: '100%',
      padding: '112px 0 16px 0',
      [theme.breakpoints.down('md')]: {
        padding: '100px 0 28px 0',
      },
      [theme.breakpoints.only('xs')]: {
        padding: '49px 0 13px 0',
      },
    },
    linksContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
    primaryLinksContainer: {
      marginBottom: '82px',
      [theme.breakpoints.down('md')]: {
        marginBottom: '30px',
      },
      [theme.breakpoints.only('xs')]: {
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-around',
        minHeight: '232px',
      },
    },
    footerTextContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
    },
  }),
);
export const CrdFooter: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  return (
    <div className={classes.root}>
      <Container className={classes.container}>
        <div
          className={clsx(
            classes.linksContainer,
            classes.primaryLinksContainer,
          )}
        >
          <CrdFooterLinks links={FOOTER_LINKS} variant="primary" />
        </div>
        <div className={classes.footerTextContainer}>
          <CrdFooterText text={t('FOOTER.COPYRIGHT', { currentYear })} />
        </div>
        <div className={clsx(classes.linksContainer)}>
          <CrdFooterLinks links={FOOTER_SECONDARY_LINKS} variant="secondary" />
        </div>
      </Container>
    </div>
  );
};
