export const mockedProduct = {
  id: 'd140c6cb-e1de-451e-b25f-c16b0562a045',
  displayName: 'iPhone 11 Pro',
  description:
    '<div class="mobile-image">\n  <img\n    src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-mobile-description.jpg"\n    alt="iPhone 11 pro description"\n  />\n  <a\n    href="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/product-comparison.jpg"\n    target="_blank"\n  >\n    <img\n      src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-mobile-cta.jpg"\n      alt="iPhone 11 pro description"\n    />\n  </a>\n  <img\n    src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-mobile-footer.jpg"\n    alt="iPhone 11 pro description"\n  />\n</div>\n<div class="desktop-image">\n  <img\n    src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-description.jpg"\n    alt="iPhone 11 pro description"\n  />\n  <a\n    href="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/product-comparison.jpg"\n    target="_blank"\n  >\n    <img\n      src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-cta.jpg"\n      alt="iPhone 11 pro description"\n    />\n  </a>\n  <img\n    src="https://marketplace-api-prod-product-catalog.s3.amazonaws.com/descriptions/iphone11pro-footer.jpg"\n    alt="iPhone 11 pro description"\n  />\n</div>\n\n<style>\n  @media (max-width: 599px) {\n    .mobile-image {\n      display: block;\n    }\n\n    .desktop-image {\n      display: none;\n    }\n  }\n\n  @media (min-width: 600px) {\n    .mobile-image {\n      display: none;\n    }\n\n    .desktop-image {\n      display: block;\n    }\n  }\n</style>',
  variations: [
    {
      id: 'bd263b9b-ab44-4149-8ac3-623dcdf18fd4',
      sku: '111',
      color: {
        code: '#FAD7BD',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.GOLD',
      },
      capacity: '512GB',
      minimumMonthlyPrice: 'R$ 289,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155790/image-64696cfb2f544996a8fbf782036103ad.jpg?v=637091098778230000',
        'https://creditas.vteximg.com.br/arquivos/ids/155789/image-74265a6d5f1b4a27a89ea778cdd0b09f.jpg?v=637091098778100000',
        'https://creditas.vteximg.com.br/arquivos/ids/155788/image-f37783e1fe464265a5b1a068cda1c5d9.jpg?v=637091098778000000',
        'https://creditas.vteximg.com.br/arquivos/ids/155787/image-06ba3bd8bf7f4a4d9b002d577ce600c9.jpg?v=637091098777870000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155790/image-64696cfb2f544996a8fbf782036103ad.jpg?v=637091098778230000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155790/image-64696cfb2f544996a8fbf782036103ad.jpg?v=637091098778230000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155789/image-74265a6d5f1b4a27a89ea778cdd0b09f.jpg?v=637091098778100000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155789/image-74265a6d5f1b4a27a89ea778cdd0b09f.jpg?v=637091098778100000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155788/image-f37783e1fe464265a5b1a068cda1c5d9.jpg?v=637091098778000000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155788/image-f37783e1fe464265a5b1a068cda1c5d9.jpg?v=637091098778000000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155787/image-06ba3bd8bf7f4a4d9b002d577ce600c9.jpg?v=637091098777870000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155787/image-06ba3bd8bf7f4a4d9b002d577ce600c9.jpg?v=637091098777870000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '84badd6b-cf1c-4080-b5ca-78d94356aa82',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 374,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 8.999,00',
        },
        {
          id: '2aef1bcc-0c33-4ad0-9225-5b5a90c80448',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.389913429',
          monthlyPrice: 'R$ 289,90',
          totalPrice: 'R$ 9.657,30',
          balloonPrice: 'R$ 2.699,70',
        },
      ],
      sellerPrice: 'R$ 7.820,13',
      enabled: true,
    },
    {
      id: '9d1637dc-d399-4cf4-bbdf-2e566a7580ba',
      sku: '109',
      color: {
        code: '#FAD7BD',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.GOLD',
      },
      capacity: '256GB',
      minimumMonthlyPrice: 'R$ 249,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155778/image-6dbb05116fb6447e8780ce60b43d6b8a.jpg?v=637091098758000000',
        'https://creditas.vteximg.com.br/arquivos/ids/155777/image-8dc97046047f411daea4c77358178578.jpg?v=637091098757900000',
        'https://creditas.vteximg.com.br/arquivos/ids/155780/image-eb7e7bb9ece84d81b31e1b8fdb754f6a.jpg?v=637091098758600000',
        'https://creditas.vteximg.com.br/arquivos/ids/155779/image-373b6aa23e34492d957a672bad28f8cf.jpg?v=637091098758530000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155778/image-6dbb05116fb6447e8780ce60b43d6b8a.jpg?v=637091098758000000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155778/image-6dbb05116fb6447e8780ce60b43d6b8a.jpg?v=637091098758000000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155777/image-8dc97046047f411daea4c77358178578.jpg?v=637091098757900000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155777/image-8dc97046047f411daea4c77358178578.jpg?v=637091098757900000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155780/image-eb7e7bb9ece84d81b31e1b8fdb754f6a.jpg?v=637091098758600000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155780/image-eb7e7bb9ece84d81b31e1b8fdb754f6a.jpg?v=637091098758600000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155779/image-373b6aa23e34492d957a672bad28f8cf.jpg?v=637091098758530000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155779/image-373b6aa23e34492d957a672bad28f8cf.jpg?v=637091098758530000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '8b8de3a6-9524-4c4f-b789-a8caa86e2e4f',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 324,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 7.799,00',
        },
        {
          id: 'cba29a4a-1d7a-4d93-aa45-120372deeab5',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.362471338',
          monthlyPrice: 'R$ 249,90',
          totalPrice: 'R$ 8.337,30',
          balloonPrice: 'R$ 2.339,70',
        },
      ],
      sellerPrice: 'R$ 6.777,33',
      enabled: false,
    },
    {
      id: '30f5f3d8-9c31-4f96-b0ca-66af454c107b',
      sku: '24',
      color: {
        code: '#535150',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SPACE_GRAY',
      },
      capacity: '512GB',
      minimumMonthlyPrice: 'R$ 301,13',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155485/image-0741bd1c19ba46bf99b9455b8d8fd578.jpg?v=637091086290400000',
        'https://creditas.vteximg.com.br/arquivos/ids/155483/image-48f77c3d4e7a4062a348a69aeed49024.jpg?v=637091086289600000',
        'https://creditas.vteximg.com.br/arquivos/ids/155481/image-cc96ce14ca7147e7903a41c75a7f648e.jpg?v=637091086289330000',
        'https://creditas.vteximg.com.br/arquivos/ids/155482/image-bf60fb968d4c493d816e94cdde0b596c.jpg?v=637091086289570000',
        'https://creditas.vteximg.com.br/arquivos/ids/155484/image-5e8dce36100d40b3a5d0e8b390f41115.jpg?v=637091086289630000',
        'https://creditas.vteximg.com.br/arquivos/ids/155480/image-e8d101c18707418b81e3a197d9ab342e.jpg?v=637091086289070000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155485/image-0741bd1c19ba46bf99b9455b8d8fd578.jpg?v=637091086290400000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155485/image-0741bd1c19ba46bf99b9455b8d8fd578.jpg?v=637091086290400000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155483/image-48f77c3d4e7a4062a348a69aeed49024.jpg?v=637091086289600000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155483/image-48f77c3d4e7a4062a348a69aeed49024.jpg?v=637091086289600000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155481/image-cc96ce14ca7147e7903a41c75a7f648e.jpg?v=637091086289330000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155481/image-cc96ce14ca7147e7903a41c75a7f648e.jpg?v=637091086289330000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155482/image-bf60fb968d4c493d816e94cdde0b596c.jpg?v=637091086289570000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155482/image-bf60fb968d4c493d816e94cdde0b596c.jpg?v=637091086289570000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155484/image-5e8dce36100d40b3a5d0e8b390f41115.jpg?v=637091086289630000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155484/image-5e8dce36100d40b3a5d0e8b390f41115.jpg?v=637091086289630000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155480/image-e8d101c18707418b81e3a197d9ab342e.jpg?v=637091086289070000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155480/image-e8d101c18707418b81e3a197d9ab342e.jpg?v=637091086289070000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '814f3dab-a385-4d34-8446-3d5c80cd1323',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 374,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 8.999,00',
        },
        {
          id: '5b981812-7566-4b1b-bce7-da719a9d9e65',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.362471338',
          monthlyPrice: 'R$ 301,13',
          totalPrice: 'R$ 9.566,90',
          balloonPrice: 'R$ 2.339,70',
        },
      ],
      sellerPrice: 'R$ 7.820,13',
      enabled: true,
    },
    {
      id: 'c3abeb5d-abf8-4a60-920e-1db5ecee2dea',
      sku: '39',
      color: {
        code: '#535150',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SPACE_GRAY',
      },
      capacity: '256GB',
      minimumMonthlyPrice: 'R$ 264,57',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155549/image-63e4d8caac3a4def9a690cedf8e9d8dc.jpg?v=637091090868530000',
        'https://creditas.vteximg.com.br/arquivos/ids/155544/image-2dd070e7d0b3495db568fc53ea5af937.jpg?v=637091090865100000',
        'https://creditas.vteximg.com.br/arquivos/ids/155546/image-ceafecdca55246d6b01391c6998b17b5.jpg?v=637091090867300000',
        'https://creditas.vteximg.com.br/arquivos/ids/155547/image-b1a1ffbaca6844e397443acce78c7688.jpg?v=637091090867370000',
        'https://creditas.vteximg.com.br/arquivos/ids/155548/image-177d827408a6445586d86f8d28b23588.jpg?v=637091090867700000',
        'https://creditas.vteximg.com.br/arquivos/ids/155545/image-5d3e651062204bf88f318055917bc8b6.jpg?v=637091090867270000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155549/image-63e4d8caac3a4def9a690cedf8e9d8dc.jpg?v=637091090868530000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155549/image-63e4d8caac3a4def9a690cedf8e9d8dc.jpg?v=637091090868530000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155544/image-2dd070e7d0b3495db568fc53ea5af937.jpg?v=637091090865100000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155544/image-2dd070e7d0b3495db568fc53ea5af937.jpg?v=637091090865100000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155546/image-ceafecdca55246d6b01391c6998b17b5.jpg?v=637091090867300000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155546/image-ceafecdca55246d6b01391c6998b17b5.jpg?v=637091090867300000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155547/image-b1a1ffbaca6844e397443acce78c7688.jpg?v=637091090867370000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155547/image-b1a1ffbaca6844e397443acce78c7688.jpg?v=637091090867370000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155548/image-177d827408a6445586d86f8d28b23588.jpg?v=637091090867700000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155548/image-177d827408a6445586d86f8d28b23588.jpg?v=637091090867700000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155545/image-5d3e651062204bf88f318055917bc8b6.jpg?v=637091090867270000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155545/image-5d3e651062204bf88f318055917bc8b6.jpg?v=637091090867270000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '10f60822-3e6d-4172-8fb5-1b67254d42b3',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 324,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 7.799,00',
        },
        {
          id: 'e25bdbb7-83dc-422d-a13b-0ed979c1638e',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.490592167',
          monthlyPrice: 'R$ 264,57',
          totalPrice: 'R$ 8.449,36',
          balloonPrice: 'R$ 2.099,70',
        },
      ],
      sellerPrice: 'R$ 6.777,33',
      enabled: false,
    },
    {
      id: '12bd841b-d139-45b2-9e56-056e046d5d11',
      sku: '6',
      color: {
        code: '#EBEBE3',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SILVER',
      },
      capacity: '64GB',
      minimumMonthlyPrice: 'R$ 229,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155409/image-9404514aa131489ba044540eb65dbd95.jpg?v=637091081569200000',
        'https://creditas.vteximg.com.br/arquivos/ids/155408/image-8b2b81b81e39464bb5b8f30f6a9f0a1a.jpg?v=637091081568070000',
        'https://creditas.vteximg.com.br/arquivos/ids/155410/image-0f2693d5cd3746b5b4999f2a950546b0.jpg?v=637091081572170000',
        'https://creditas.vteximg.com.br/arquivos/ids/155412/image-7bd60dd1cf8c4246b2acb16b99f6c4d1.jpg?v=637091081572800000',
        'https://creditas.vteximg.com.br/arquivos/ids/155411/image-66efc7fd8a624ceea607d93e2fa0f077.jpg?v=637091081572300000',
        'https://creditas.vteximg.com.br/arquivos/ids/155407/image-c17f7560388f48c9a4af0d30a0a4133e.jpg?v=637091081566830000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155409/image-9404514aa131489ba044540eb65dbd95.jpg?v=637091081569200000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155409/image-9404514aa131489ba044540eb65dbd95.jpg?v=637091081569200000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155408/image-8b2b81b81e39464bb5b8f30f6a9f0a1a.jpg?v=637091081568070000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155408/image-8b2b81b81e39464bb5b8f30f6a9f0a1a.jpg?v=637091081568070000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155410/image-0f2693d5cd3746b5b4999f2a950546b0.jpg?v=637091081572170000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155410/image-0f2693d5cd3746b5b4999f2a950546b0.jpg?v=637091081572170000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155412/image-7bd60dd1cf8c4246b2acb16b99f6c4d1.jpg?v=637091081572800000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155412/image-7bd60dd1cf8c4246b2acb16b99f6c4d1.jpg?v=637091081572800000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155411/image-66efc7fd8a624ceea607d93e2fa0f077.jpg?v=637091081572300000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155411/image-66efc7fd8a624ceea607d93e2fa0f077.jpg?v=637091081572300000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155407/image-c17f7560388f48c9a4af0d30a0a4133e.jpg?v=637091081566830000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155407/image-c17f7560388f48c9a4af0d30a0a4133e.jpg?v=637091081566830000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '7bb5a692-c121-42a4-a6ca-140a2f7d97ba',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 291,62',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 6.999,00',
        },
        {
          id: '578626d1-3a41-4d32-b0f4-ccd6d3828837',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.490592167',
          monthlyPrice: 'R$ 229,90',
          totalPrice: 'R$ 7.617,30',
          balloonPrice: 'R$ 2.099,70',
        },
      ],
      sellerPrice: 'R$ 6.082,13',
      enabled: true,
    },
    {
      id: '0fe022a6-a6a3-4cc9-b2f1-8a8ade6d9366',
      sku: '27',
      color: {
        code: '#4E5851',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.MIDNIGHT_GREEN',
      },
      capacity: '64GB',
      minimumMonthlyPrice: 'R$ 229,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
        'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
        'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
        'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
        'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
        'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '12a193bd-f49f-4227-bb95-2cf525062e63',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 291,62',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 6.999,00',
        },
        {
          id: 'd669697c-a5e9-4985-b5b5-4220673d82e8',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.490592167',
          monthlyPrice: 'R$ 229,90',
          totalPrice: 'R$ 7.617,30',
          balloonPrice: 'R$ 2.099,70',
        },
      ],
      sellerPrice: 'R$ 6.082,13',
      enabled: true,
    },
    {
      id: '40060cb0-a791-4a1f-8bd9-ed916b007831',
      sku: '3',
      color: {
        code: '#FAD7BD',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.GOLD',
      },
      capacity: '64GB',
      minimumMonthlyPrice: 'R$ 229,90',
      images: [],
      media: [],
      paymentMethods: [
        {
          id: '71d139bd-df23-4071-9473-af306845f4f9',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 291,62',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 6.999,00',
        },
        {
          id: '6ccc2de9-d5d6-4d90-b28c-4c8c6579a23f',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.490592167',
          monthlyPrice: 'R$ 229,90',
          totalPrice: 'R$ 7.617,30',
          balloonPrice: 'R$ 2.099,70',
        },
      ],
      sellerPrice: 'R$ 6.082,13',
      enabled: false,
    },
    {
      id: '541deb74-e83c-4502-9714-296907be97c4',
      sku: '21',
      color: {
        code: '#535150',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SPACE_GRAY',
      },
      capacity: '64GB',
      minimumMonthlyPrice: 'R$ 229,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155473/image-f63e5195fb9d47a6875dea14133832fa.jpg?v=637091086262130000',
        'https://creditas.vteximg.com.br/arquivos/ids/155475/image-e134dba83c3d42e5b3a8df76764c6749.jpg?v=637091086262330000',
        'https://creditas.vteximg.com.br/arquivos/ids/155472/image-0516834933ff42fe9899f528409008f7.jpg?v=637091086262000000',
        'https://creditas.vteximg.com.br/arquivos/ids/155474/image-66a45c9d0393439ea975fb9ea9544388.jpg?v=637091086262170000',
        'https://creditas.vteximg.com.br/arquivos/ids/155470/image-d77eac94fef04dcda604acdeb43f84bf.jpg?v=637091086261870000',
        'https://creditas.vteximg.com.br/arquivos/ids/155471/image-befc8f2c97644794a8328e38973df321.jpg?v=637091086261900000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155473/image-f63e5195fb9d47a6875dea14133832fa.jpg?v=637091086262130000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155473/image-f63e5195fb9d47a6875dea14133832fa.jpg?v=637091086262130000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155475/image-e134dba83c3d42e5b3a8df76764c6749.jpg?v=637091086262330000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155475/image-e134dba83c3d42e5b3a8df76764c6749.jpg?v=637091086262330000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155472/image-0516834933ff42fe9899f528409008f7.jpg?v=637091086262000000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155472/image-0516834933ff42fe9899f528409008f7.jpg?v=637091086262000000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155474/image-66a45c9d0393439ea975fb9ea9544388.jpg?v=637091086262170000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155474/image-66a45c9d0393439ea975fb9ea9544388.jpg?v=637091086262170000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155470/image-d77eac94fef04dcda604acdeb43f84bf.jpg?v=637091086261870000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155470/image-d77eac94fef04dcda604acdeb43f84bf.jpg?v=637091086261870000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155471/image-befc8f2c97644794a8328e38973df321.jpg?v=637091086261900000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155471/image-befc8f2c97644794a8328e38973df321.jpg?v=637091086261900000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: 'd1ff8d7e-48cd-47b1-b66e-54a810784305',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 291,62',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 6.999,00',
        },
        {
          id: 'ae03dcc1-2eb4-4c27-9b65-e0f8152eb570',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.490592167',
          monthlyPrice: 'R$ 229,90',
          totalPrice: 'R$ 7.617,30',
          balloonPrice: 'R$ 2.099,70',
        },
      ],
      sellerPrice: 'R$ 6.082,13',
      enabled: true,
    },
    {
      id: 'e752461b-949c-4161-89f2-5612fcbc0be0',
      sku: '70',
      color: {
        code: '#4E5851',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.MIDNIGHT_GREEN',
      },
      capacity: '512GB',
      minimumMonthlyPrice: 'R$ 289,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155632/image-b80d84a574484cc38adb8442d1c5d8f5.jpg?v=637091093518500000',
        'https://creditas.vteximg.com.br/arquivos/ids/155631/image-5b84a935e973454d809d4ccbab5536a7.jpg?v=637091093518370000',
        'https://creditas.vteximg.com.br/arquivos/ids/155636/image-d07a44cc66d6460da25b308cd954181c.jpg?v=637091093519030000',
        'https://creditas.vteximg.com.br/arquivos/ids/155635/image-47ad06979a7a4a5f8cf0d07d3a79831f.jpg?v=637091093518800000',
        'https://creditas.vteximg.com.br/arquivos/ids/155633/image-72f9db90df814764b1e0a3422b7459fe.jpg?v=637091093518600000',
        'https://creditas.vteximg.com.br/arquivos/ids/155634/image-0d835a25cf044960985aee43c3e1fd35.jpg?v=637091093518770000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155632/image-b80d84a574484cc38adb8442d1c5d8f5.jpg?v=637091093518500000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155632/image-b80d84a574484cc38adb8442d1c5d8f5.jpg?v=637091093518500000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155631/image-5b84a935e973454d809d4ccbab5536a7.jpg?v=637091093518370000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155631/image-5b84a935e973454d809d4ccbab5536a7.jpg?v=637091093518370000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155636/image-d07a44cc66d6460da25b308cd954181c.jpg?v=637091093519030000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155636/image-d07a44cc66d6460da25b308cd954181c.jpg?v=637091093519030000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155635/image-47ad06979a7a4a5f8cf0d07d3a79831f.jpg?v=637091093518800000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155635/image-47ad06979a7a4a5f8cf0d07d3a79831f.jpg?v=637091093518800000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155633/image-72f9db90df814764b1e0a3422b7459fe.jpg?v=637091093518600000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155633/image-72f9db90df814764b1e0a3422b7459fe.jpg?v=637091093518600000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155634/image-0d835a25cf044960985aee43c3e1fd35.jpg?v=637091093518770000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155634/image-0d835a25cf044960985aee43c3e1fd35.jpg?v=637091093518770000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '56bd67b8-7358-4438-b544-ea2158328511',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 374,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 8.999,00',
        },
        {
          id: '32c1e84c-5e3c-4ef3-a16c-ed9736ea0506',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.389913429',
          monthlyPrice: 'R$ 289,90',
          totalPrice: 'R$ 9.657,30',
          balloonPrice: 'R$ 2.699,70',
        },
      ],
      sellerPrice: 'R$ 7.820,13',
      enabled: true,
    },
    {
      id: '44a94376-82f8-4234-ace9-a8b9d8bf3b82',
      sku: '30',
      color: {
        code: '#EBEBE3',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SILVER',
      },
      capacity: '256GB',
      minimumMonthlyPrice: 'R$ 249,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155508/image-e90300c8302247dfa967e336d9e1561f.jpg?v=637091088432200000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155508/image-e90300c8302247dfa967e336d9e1561f.jpg?v=637091088432200000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155508/image-e90300c8302247dfa967e336d9e1561f.jpg?v=637091088432200000',
          type: 'IMAGE',
        },
      ],
      paymentMethods: [
        {
          id: '5d4ff854-7948-471f-b604-1d07905c9440',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 324,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 7.799,00',
        },
        {
          id: 'b97898a6-178c-4389-b799-efd81bb28b6f',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.362471338',
          monthlyPrice: 'R$ 249,90',
          totalPrice: 'R$ 8.337,30',
          balloonPrice: 'R$ 2.339,70',
        },
      ],
      sellerPrice: 'R$ 6.777,33',
      enabled: false,
    },
    {
      id: '0287a9a0-00ee-4ae7-895d-10af5ed33f7d',
      sku: '87',
      color: {
        code: '#4E5851',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.MIDNIGHT_GREEN',
      },
      capacity: '256GB',
      minimumMonthlyPrice: 'R$ 249,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155712/image-2bf57dc3d3fd4aa2854dff881a4a4e2b.jpg?v=637091095668870000',
        'https://creditas.vteximg.com.br/arquivos/ids/155711/image-338caeabce7e47da93520bbad0c32f84.jpg?v=637091095667470000',
        'https://creditas.vteximg.com.br/arquivos/ids/155713/image-4b21c45ff5f948e2b5b4aa280db7a8bf.jpg?v=637091095669430000',
        'https://creditas.vteximg.com.br/arquivos/ids/155708/image-71f59c44fe2e4485b05a034aa8715144.jpg?v=637091095667270000',
        'https://creditas.vteximg.com.br/arquivos/ids/155709/image-1b044707baac497f9aa866810ac64059.jpg?v=637091095667400000',
        'https://creditas.vteximg.com.br/arquivos/ids/155710/image-dbfc38953c4749fe8b367fabdad859c6.jpg?v=637091095667430000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155712/image-2bf57dc3d3fd4aa2854dff881a4a4e2b.jpg?v=637091095668870000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155712/image-2bf57dc3d3fd4aa2854dff881a4a4e2b.jpg?v=637091095668870000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155711/image-338caeabce7e47da93520bbad0c32f84.jpg?v=637091095667470000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155711/image-338caeabce7e47da93520bbad0c32f84.jpg?v=637091095667470000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155713/image-4b21c45ff5f948e2b5b4aa280db7a8bf.jpg?v=637091095669430000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155713/image-4b21c45ff5f948e2b5b4aa280db7a8bf.jpg?v=637091095669430000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155708/image-71f59c44fe2e4485b05a034aa8715144.jpg?v=637091095667270000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155708/image-71f59c44fe2e4485b05a034aa8715144.jpg?v=637091095667270000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155709/image-1b044707baac497f9aa866810ac64059.jpg?v=637091095667400000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155709/image-1b044707baac497f9aa866810ac64059.jpg?v=637091095667400000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155710/image-dbfc38953c4749fe8b367fabdad859c6.jpg?v=637091095667430000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155710/image-dbfc38953c4749fe8b367fabdad859c6.jpg?v=637091095667430000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: '1c345cdc-89aa-4e65-a18c-677b460f03a5',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 324,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 7.799,00',
        },
        {
          id: '7db8e0d1-5e0c-4cc3-b703-48daa70ecf00',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.362471338',
          monthlyPrice: 'R$ 249,90',
          totalPrice: 'R$ 8.337,30',
          balloonPrice: 'R$ 2.339,70',
        },
      ],
      sellerPrice: 'R$ 6.777,33',
      enabled: true,
    },
    {
      id: '6b7c0869-5052-4bf4-92b9-97aac9b216a8',
      sku: '106',
      color: {
        code: '#EBEBE3',
        nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.SILVER',
      },
      capacity: '512GB',
      minimumMonthlyPrice: 'R$ 289,90',
      images: [
        'https://creditas.vteximg.com.br/arquivos/ids/155769/image-a87c513a1af942d89834ec11ea6d8021.jpg?v=637091096791000000',
        'https://creditas.vteximg.com.br/arquivos/ids/155768/image-dc71bad7c1894ec481d94d302b79896a.jpg?v=637091096790630000',
        'https://creditas.vteximg.com.br/arquivos/ids/155766/image-8c7f76db0e3a4fe4ae444949bdfb2b62.jpg?v=637091096790530000',
        'https://creditas.vteximg.com.br/arquivos/ids/155770/image-a2780b6a70b34b32bd05410dcc130e4d.jpg?v=637091096791300000',
        'https://creditas.vteximg.com.br/arquivos/ids/155767/image-728bde1f300c4a918d9c8ed083067c17.jpg?v=637091096790630000',
        'https://creditas.vteximg.com.br/arquivos/ids/155765/image-7f0e46a636804d00af0add7cd5e2b385.jpg?v=637091096790500000',
      ],
      media: [
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155769/image-a87c513a1af942d89834ec11ea6d8021.jpg?v=637091096791000000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155769/image-a87c513a1af942d89834ec11ea6d8021.jpg?v=637091096791000000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155768/image-dc71bad7c1894ec481d94d302b79896a.jpg?v=637091096790630000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155768/image-dc71bad7c1894ec481d94d302b79896a.jpg?v=637091096790630000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155766/image-8c7f76db0e3a4fe4ae444949bdfb2b62.jpg?v=637091096790530000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155766/image-8c7f76db0e3a4fe4ae444949bdfb2b62.jpg?v=637091096790530000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155770/image-a2780b6a70b34b32bd05410dcc130e4d.jpg?v=637091096791300000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155770/image-a2780b6a70b34b32bd05410dcc130e4d.jpg?v=637091096791300000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155767/image-728bde1f300c4a918d9c8ed083067c17.jpg?v=637091096790630000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155767/image-728bde1f300c4a918d9c8ed083067c17.jpg?v=637091096790630000',
          type: 'IMAGE',
        },
        {
          source:
            'https://creditas.vteximg.com.br/arquivos/ids/155765/image-7f0e46a636804d00af0add7cd5e2b385.jpg?v=637091096790500000',
          thumbnail:
            'https://creditas.vteximg.com.br/arquivos/ids/155765/image-7f0e46a636804d00af0add7cd5e2b385.jpg?v=637091096790500000',
          type: 'IMAGE',
        },
        {
          source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
          thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
          type: 'VIDEO',
        },
      ],
      paymentMethods: [
        {
          id: 'c0a06aa4-11c3-4b59-bb19-e5e3cb73c3b5',
          type: 'FULL',
          installment: 24,
          monthlyPrice: 'R$ 374,96',
          interestPercentage: '1.155187482',
          totalPrice: 'R$ 8.999,00',
        },
        {
          id: '55363562-ed68-4cf1-9b5b-958975b702b8',
          type: 'BALLOON',
          installment: 24,
          balloonPercentage: 30.0,
          interestPercentage: '1.389913429',
          monthlyPrice: 'R$ 289,90',
          totalPrice: 'R$ 9.657,30',
          balloonPrice: 'R$ 2.699,70',
        },
      ],
      sellerPrice: 'R$ 7.820,13',
      enabled: true,
    },
  ],
  defaultVariation: {
    id: '0fe022a6-a6a3-4cc9-b2f1-8a8ade6d9366',
    sku: '27',
    color: {
      code: '#4E5851',
      nameLiteral: 'BACKEND.PRODUCT.CONFIGURATION.COLOR.MIDNIGHT_GREEN',
    },
    capacity: '64GB',
    minimumMonthlyPrice: 'R$ 229,90',
    images: [
      'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
      'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
      'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
      'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
      'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
      'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
    ],
    media: [
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155495/image-9efbf1afb11c4583819024ea2a2db464.jpg?v=637091088392730000',
        type: 'IMAGE',
      },
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155491/image-61a8304ea21145adbdc1610be6e05c6e.jpg?v=637091088391430000',
        type: 'IMAGE',
      },
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155490/image-466844ac40f14087840fc9426b6148f1.jpg?v=637091088391370000',
        type: 'IMAGE',
      },
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155492/image-15359ff877744f2193b5c7d4cfa3d88b.jpg?v=637091088392030000',
        type: 'IMAGE',
      },
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155494/image-a8cd03093e92474eb923d329198791ae.jpg?v=637091088392430000',
        type: 'IMAGE',
      },
      {
        source:
          'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
        thumbnail:
          'https://creditas.vteximg.com.br/arquivos/ids/155493/image-c62583456b9944cba255a30f2e6b0298.jpg?v=637091088392130000',
        type: 'IMAGE',
      },
      {
        source: 'https://www.youtube.com/embed/cVEemOmHw9Y',
        thumbnail: 'http://i3.ytimg.com/vi/cVEemOmHw9Y/hqdefault.jpg',
        type: 'VIDEO',
      },
    ],
    paymentMethods: [
      {
        id: '12a193bd-f49f-4227-bb95-2cf525062e63',
        type: 'FULL',
        installment: 24,
        monthlyPrice: 'R$ 291,62',
        interestPercentage: '1.155187482',
        totalPrice: 'R$ 6.999,00',
      },
      {
        id: 'd669697c-a5e9-4985-b5b5-4220673d82e8',
        type: 'BALLOON',
        installment: 24,
        balloonPercentage: 30.0,
        interestPercentage: '1.490592167',
        monthlyPrice: 'R$ 229,90',
        totalPrice: 'R$ 7.617,30',
        balloonPrice: 'R$ 2.099,70',
      },
    ],
    sellerPrice: 'R$ 6.082,13',
    enabled: true,
  },
};

export const mockedProductDetail = `<link rel="stylesheet" href="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/shared-style.css"/><div class="mobile-image"><img  src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/mobile-description.jpg" alt="iPhone 11 Pro description"/>
<a href="https://marketplace-staging.creditas.com.br/iphone-comparison" target="_blank"><img src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/mobile-cta.jpg" alt="iPhone 11 Pro description"/></a>
<img src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/mobile-footer.jpg" alt="iPhone 11 Pro description"/></div>
<div class="desktop-image"><img src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/description.jpg"  alt="iPhone 11 Pro description"/><a href="https://marketplace-staging.creditas.com.br/iphone-comparison" target="_blank">
<img src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/cta.jpg" alt="iPhone 11 Pro description"/></a>
<img src="https://marketplace-api-staging-product-catalog.s3.amazonaws.com/products/categories/apple/iphone11pro/footer.jpg" alt="iPhone 11 Pro description"/>
</div>`;
