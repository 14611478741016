import {
  MOCKED_USER_ID,
  PAYMENT_METHOD_ID,
} from '../constants/purchaseDataConstants';
import { mockedProduct, mockedProductDetail } from './product.data';

export const getProductMock = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedProduct)));
  });
};

export const getProductDescription = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(mockedProductDetail));
  });
};

export const addDocumentsToUser = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response());
  });
};

const mockedUserResponse = {
  paymentMethodId: PAYMENT_METHOD_ID,
  useShippingAddress: false,
  userId: MOCKED_USER_ID,
};

export const createOrder = (): Promise<Response> => {
  return new Promise((resolve): void => {
    resolve(new Response(JSON.stringify(mockedUserResponse)));
  });
};
