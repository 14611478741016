import React from 'react';
import { useTranslation } from 'react-i18next';

import { createStyles, Divider, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

import { HOW_IT_WORKS_ITEMS } from 'domain/how-it-works/how-it-works.constants';
import { CrdHowItWorksItem } from 'modules/how-it-works/components/how-it-works-item.component';

import { CrdMarketplacePage } from '../misc/marketplace.page';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      textAlign: 'center',
      lineHeight: '72px',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('md')]: {
        fontSize: '40px',
        lineHeight: '60px',
      },
      [theme.breakpoints.only('xs')]: {
        fontSize: '24px',
        lineHeight: '36px',
      },
    },
    description: {
      textAlign: 'center',
      lineHeight: '24px',
      fontWeight: 'normal',
      color: theme.palette.text.primary,
      [theme.breakpoints.down('md')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
    },
    itemsContainer: {
      display: 'grid',
      gridTemplateColumns: '1fr 4fr 1fr',
      columnGap: theme.layout.columnGap,
      [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
      },
    },
    divider: {
      gridColumn: '2',
      margin: '47px 0 0 0',
      [theme.breakpoints.down('md')]: {
        marginTop: '56px',
        gridColumn: '1',
      },
      [theme.breakpoints.only('xs')]: {
        marginTop: '32px',
      },
    },
    item: {
      gridColumn: '2',
      [theme.breakpoints.down('md')]: {
        gridColumn: '1',
      },
    },
  }),
);

export const HowItWorksPage: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <CrdMarketplacePage>
      <Typography className={classes.title} variant="h2" component="h1">
        {t('HOW_IT_WORKS.HEADLINE.TITLE')}
      </Typography>
      <div className={classes.itemsContainer}>
        <Divider className={classes.divider}></Divider>
        {HOW_IT_WORKS_ITEMS.map(howItWorksItem => {
          return (
            <CrdHowItWorksItem
              key={howItWorksItem.number}
              className={classes.item}
              item={howItWorksItem}
            ></CrdHowItWorksItem>
          );
        })}
      </div>
    </CrdMarketplacePage>
  );
};
