import './i18n';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { SplitProvider } from 'react-splitio';
import * as serviceWorker from 'serviceWorker';

import { ThemeProvider } from '@material-ui/styles';

import { tracking } from 'domain/tracking/tracking.utils';
import { TrackingProvider } from 'modules/shared/hooks/use-tracking.hook';
import { store } from 'redux/store';
import { CrdTagManager } from 'services/tagmanager/tagmanager.service';
import { marketplaceTheme } from 'styles/marketplace.theme';

import App from './App';
import { SPLIT_IO_SDK_CONFIG } from './split-io';

CrdTagManager.initialize();

ReactDOM.render(
  <Provider store={store}>
    <TrackingProvider value={tracking}>
      <ThemeProvider theme={marketplaceTheme}>
        <SplitProvider config={SPLIT_IO_SDK_CONFIG}>
          <App />
        </SplitProvider>
      </ThemeProvider>
    </TrackingProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
